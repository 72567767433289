import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { connect } from 'react-redux'
import { setAssetHost } from 'reduxStore/actions'
import store from 'reduxStore'
import ScrollToTop from 'components/global/ScrollToTop'
import ScrollWatcher from 'components/global/ScrollWatcher'

import 'style/index.scss';

import Masthead from 'components/global/Masthead'
import Footer from 'components/global/Footer'

import Home from 'pages/Home'
import How from 'pages/How'
import About from 'pages/About'
import Results from 'pages/Results'
import Contact from 'pages/Contact'
import Careers from 'pages/Careers'
import CaseStudy from 'pages/CaseStudy'
import NotFound from 'pages/NotFound'
import Capability from 'pages/Capability'
import { PATH_CASE_STUDY } from './constants'
import { PATH_CAPABILITY } from './constants'

class App extends Component {
    constructor(props) {
        super(props)

        store.dispatch(
            setAssetHost(this.props.assetHost)
        );
    }

    render() {
        return (
            <Router>
                <ScrollToTop>
                    <div className={ClassNames('body', {
                        'has-mobile-nav': this.props.mobileNav
                    })}>
                        <Masthead />

                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/how" component={How} />
                            <Route exact path="/about" component={About} />
                            <Route exact path="/work" component={Results} />
                            <Route exact path="/contact" component={Contact} />
                            <Route exact path="/careers" component={Careers} />
                            <Route exact path={PATH_CASE_STUDY} component={CaseStudy} />
                            <Route exact path={PATH_CAPABILITY} component={Capability} />
                            <Route component={NotFound} />
                        </Switch>

                        <Footer />
                    </div>
                    <ScrollWatcher />
                </ScrollToTop>
            </Router>
        )
    }
}

App.propTypes = {
    assetHost: PropTypes.string
}

const mapStateToProps = (state) => {
    return Object.assign(
        {
            mobileNav: state.presentation.mobileNav
        }
    )
}

export default connect(mapStateToProps, null)(App)
