import React from 'react'
import PropTypes from 'prop-types'

const ProgressBar = (props) => {
    return (
        <div className={'progress-bar'}>
            <span>
                {props.current < 10 ? '0' : ''}{props.current}
            </span>
            <div style={{ background: `linear-gradient(to right, #c4c4c4 ${props.percentage}%, #f3f3f4 ${props.percentage}%)` }} />
            <span>
                {props.total < 10 ? '0' : ''}{props.total}
            </span>
        </div>
    );
}

ProgressBar.propTypes = {
    current: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired
};

export default ProgressBar
