import React, { useState } from 'react'
import { Link, NavLink, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import breakpoints from 'lib/breakpoints'
import MediaQuery from 'react-responsive'
import { connect } from 'react-redux'

import store from 'reduxStore'
import { setMobileNavVisibility } from 'reduxStore/actions'

import Logo from 'components/global/Logo'
import Close from 'components/global/Close'
import Dropdown from 'components/global/Dropdown'
import SVGInline from 'react-svg-inline'
import DropdownIcon from 'images/icons/chevron.svg'

const MainNav = (props) => {
    const blockClassName = 'main-nav';
    const [dropdown, setDropdown] = useState(false);

    let _element = (name) => {
        return blockClassName + '__' + name;
    }

    let _closeMobileNav = () => store.dispatch(setMobileNavVisibility(false))

    let toggleSubmenu = () => {
        setDropdown(dropdown === false ? true : false);
    }

    //modify menu.json
    //  to determine whether to make a link of the menu item
    //  remove the href from capability object
    //  if there is no href, go down another path

    let _eachItem = (item, index) => {
        let buttonClass = 'button'

        if (!item.displayAsButton) {
            buttonClass += ' button--link'
        }

        return (
            <li className={_element('item')} key={index}>
                {item.href ? (
                    <NavLink
                        to = {item.href}
                        activeClassName = "is-active"
                        onClick = {_closeMobileNav}
                        className = {buttonClass}
                        isActive = {((match, location) => {
                            if (item.href === '/work') {
                                return location.pathname === '/work' || location.pathname.search('/case-study/') > -1
                            } else {
                                return match;
                            }
                        })}
                    >
                    <span className={'button__label'} >
                        {item.label}
                    </span>
                    </NavLink>
                ) : (
                    <>
                    {item.dropdown ? (
                        <div className = {buttonClass}>
                        <span className={'button__label'} >
                            {item.label}
                        </span>
                        <span className={'icon--dropdown'}>
                            <SVGInline
                                svg = {DropdownIcon}
                                className = "dropdown__icon"
                            />
                        </span>
                        <Dropdown classname="has-mobile-nav" dropdown={item.dropdown} onClick={toggleSubmenu} />                            
                        </div>
                        ) : (
                            <></>
                        )
                    }
                    </>
                )}
                </li>
            )
        }

    return (
        <nav className={blockClassName}>
            <MediaQuery maxWidth={breakpoints.mid}>
                <div className={_element('logo')}>
                    <Link to="/" className="u-block" onClick={_closeMobileNav}>
                        <h1 className="u-accessible-hide">Invoke</h1>
                        <Logo />
                    </Link>
                </div>
                <Close className={_element('close')} />
            </MediaQuery>
            <ul className={_element('items')}>
                {props.items.map(_eachItem)}
            </ul>
        </nav>
    )
}

MainNav.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            href: PropTypes.string,
            displayAsButton: PropTypes.bool
        }),
    ).isRequired
}

const mapStateToProps = (state) => {
    return {
        items: state.menu
    }
}

export default withRouter(connect(mapStateToProps, null)(MainNav));
