import React from 'react'
import PropTypes from 'prop-types'

import HomeHeroSlider from 'components/hero/HomeHeroSlider'

const HomeHero = (props) => {
    return (
        <div className={'home-hero'}>
            <h2 className={'home-hero__title'}>
                {props.title}
            </h2>
            <div className={'home-hero__description'}>
                {props.description}
            </div>
            <HomeHeroSlider interval={5} defaults={props.defaults} features={props.features} />
        </div>
    )
}

HomeHero.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    defaults: PropTypes.shape({
        description: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
    }).isRequired,
    features: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
    })).isRequired
}

export default HomeHero
