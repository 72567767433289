import React from 'react'
import { connect } from 'react-redux'
import Container from 'components/layout/Container'
import Collection from 'components/layout/Collection'
import OutlineItem from 'components/content/OutlineItem'

import AIIcon1 from 'images/icons/capability/01-NLP@2x.png'
import AIIcon2 from 'images/icons/capability/02-COMPVISION@2x.png'
import AIIcon3 from 'images/icons/capability/03-GENAI@2x.png'

import Web3Icon1 from 'images/icons/capability/ico-web3-1-blockchain@2x.png'
import Web3Icon2 from 'images/icons/capability/ico-web3-2-generative@2x.png'
import Web3Icon3 from 'images/icons/capability/ico-web3-3-drop@2x.png'

import MarketIcon1 from 'images/icons/capability/ico-market-1-validating@2x.png'
import MarketIcon2 from 'images/icons/capability/ico-market-2-understanding@2x.png'
import MarketIcon3 from 'images/icons/capability/ico-market-3-best@2x.png'

import B2bIcon1 from 'images/icons/capability/ico-biz-1-establish@2x.png'
import B2bIcon2 from 'images/icons/capability/ico-biz-2-targeting@2x.png'
import B2bIcon3 from 'images/icons/capability/ico-biz-3-prototypes@2x.png'
import B2bIcon4 from 'images/icons/capability/ico-biz-4-product@2x.png'

import IdeaIcon1 from 'images/icons/capability/ico-idea-1-problem@2x.png'
import IdeaIcon2 from 'images/icons/capability/ico-idea-2-idea@2x.png'
import IdeaIcon3 from 'images/icons/capability/ico-idea-3-map@2x.png'
import IdeaIcon4 from 'images/icons/capability/ico-idea-4-mvp@2x.png'

const CapabilitiesOutline = (props) => {
    const _itemsWithIcons = (items) => {
        return items.map((item) => {
            let image;

            switch (item.image_name) {
                case 'ai-icon-1':
                    image = AIIcon1;
                    break;
                case 'ai-icon-2':
                    image = AIIcon2;
                    break;
                case 'ai-icon-3':
                    image = AIIcon3;
                    break;
                case 'web3-icon-1':
                    image = Web3Icon1;
                    break;
                case 'web3-icon-2':
                    image = Web3Icon2;
                    break;
                case 'web3-icon-3':
                    image = Web3Icon3;
                    break;
                case 'marketplaces-icon-1':
                    image = MarketIcon1;
                    break;
                case 'marketplaces-icon-2':
                    image = MarketIcon2;
                    break;
                case 'marketplaces-icon-3':
                    image = MarketIcon3;
                    break;
                case 'b2b-icon-1':
                    image = B2bIcon1;
                    break;
                case 'b2b-icon-2':
                    image = B2bIcon2;
                    break;
                case 'b2b-icon-3':
                    image = B2bIcon3;
                    break;
                case 'b2b-icon-4':
                    image = B2bIcon4;
                    break;
                case 'idea-icon-1':
                    image = IdeaIcon1;
                    break;
                case 'idea-icon-2':
                    image = IdeaIcon2;
                    break;
                case 'idea-icon-3':
                    image = IdeaIcon3;
                    break;
                case 'idea-icon-4':
                    image = IdeaIcon4;
                break;
                default:
                    break;
            }

            item.image = image;
            return item;
        });
    }

    return (
        <Container>
            <Collection
                items = {_itemsWithIcons(props.items)}
                component = {OutlineItem}
                className = 'outline'
            />
        </Container>
    )
}

const mapStateToProps = (state) => {
    return state.capabilities.list
}

export default connect(mapStateToProps, null)(CapabilitiesOutline)
