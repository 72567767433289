import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Canvas from 'components/global/Canvas'
import Container from 'components/layout/Container'

const TabbedShowcase = (props) => {
    const blockClassName = 'tabbed-showcase';

    let _element = (name) => {
        return blockClassName + '__' + name;
    }

    return (
        <Canvas
            tick = {true}
            tickStyle = "checkered"
            backgroundPhoto = {props.backgroundPhoto}
        >
            <Container>
                <div className={blockClassName}>
                    <div className={_element('intro')}>
                        <h3 className={classNames(
                            't-h2',
                            _element('title')
                        )}>
                            {props.title}
                        </h3>
                        <div className={classNames(
                            't-large-body',
                            _element('description')
                        )}>
                            {props.description}
                        </div>
                    </div>
                    <div className={_element('tabs')}>
                        {props.children}
                    </div>
                </div>
            </Container>
        </Canvas>
    )
}

TabbedShowcase.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    backgroundPhoto: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.object)
}

export default TabbedShowcase

