import React from 'react'
import PropTypes from 'prop-types'
import BEM from 'lib/BEM'
import ClassNames from 'classnames'
import LilUri from 'lil-uri'
import { connect } from 'react-redux'

import Container from 'components/layout/Container'

import { imageUrl } from 'lib/utils'
import { setHeaderSpacerVisibility } from 'reduxStore/actions'

export class CaseStudyHero extends React.Component {
    componentDidMount() {
        this.props.pullHeader();
    }

    componentWillUnmount() {
        this.props.resetHeader();
    }

    render() {
        const bem = new BEM('case-study-hero');
        let props = this.props;

        return (
            <div
                className={ClassNames(bem.b, props.className)}
                style={{
                    backgroundImage: `url(${imageUrl(props.photo)})`,
                    paddingTop: props.headerHeight
                }}
            >
                <Container className={bem.e('body')}>
                    <div className={bem.e('main-info')}>
                        <h2 className={bem.e('headline')}>
                            {props.headline}
                        </h2>
                        { props.url ? (
                            <a href={props.url} className={bem.e('url')}>
                                {LilUri(props.url).hostname().replace('www.', '')}
                            </a>
                        ) : (null) }
                    </div>
                    <div className={bem.e('supplementary-content')}>
                        { props.scope && props.scope.length ? (
                            <div>
                                <div className={bem.e('keyline')} style={{backgroundColor: props.brandColor}} />
                                <ul className={bem.e('scope')}>
                                    { props.scope.map((item, index) => (
                                        <li className={bem.e('scope-item')} key={index}>
                                            {item}
                                        </li>
                                    )) }
                                </ul>
                            </div>
                        ) : (null) }
                    </div>
                </Container>
            </div>
        )
    }
}

CaseStudyHero.propTypes = {
    headline: PropTypes.string.isRequired,
    photo: PropTypes.string.isRequired,
    url: PropTypes.string,
    scope: PropTypes.arrayOf(PropTypes.string),
    brandColor: PropTypes.string
}

const mapStateToProps = (state) => ({
    headerHeight: state.presentation.headerHeight
})

const mapDispatchToProps = (dispatch) => ({
    pullHeader: () => {
        dispatch(setHeaderSpacerVisibility(false))
    },
    resetHeader: () => {
        dispatch(setHeaderSpacerVisibility(true))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(CaseStudyHero)
