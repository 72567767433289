import React from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'
import BEM from 'lib/BEM'
import Label from 'components/form/Label'
import { makeId } from 'lib/utils'

export class FormElement extends React.Component {
    constructor(props) {
        super(props);

        this.bem = new BEM('form-element');

        this.state = {
            hasFocus: false
        };

        this.childProps = this.props.children.props;
    }

    _handleFocus() {
        this.setState({
            hasFocus: true
        });

        if (this.childProps && this.childProps.inputFocus) {
            this.childProps.inputFocus();
        }
    }

    _hanldeBlur() {
        this.setState({
            hasFocus: false
        });

        if (this.childProps && this.childProps.inputBlur) {
            this.childProps.inputBlur();
        }
    }

    render() {
        const props = this.props;
        const inputId = makeId();
        const labelId = makeId();
        const bem = this.bem;

        const inputWithIds = React.Children.only(React.cloneElement(props.children, {
            id: inputId,
            labelId: labelId,
            inputFocus: (event) => this._handleFocus(event),
            inputBlur: (event) => this._hanldeBlur(event)
        }));

        return (
            <div className={ClassNames(bem.b, 'form__input', props.className, {
                'has-focus': this.state.hasFocus,
                'has-value': this.props.hasValue,
                [bem.m('placeholder-label')]: this.props.placeholderLabel,
                [bem.m(this.childProps.type)]: typeof(this.childProps.type) !== undefined
                }
            )}>
                {props.label && props.labelPosition === 'before' ? (
                    <Label
                        className={ClassNames(props.labelClassname, bem.e('label'), {
                            'u-screen-reader': props.labelHidden,
                            'has-instruction': props.instruction
                        })}
                        id={labelId}
                        htmlFor={inputId}
                    >
                        {props.label}

                        {this.props.instruction ? (
                            <span className={bem.e('instruction')}>
                                {this.props.instruction}
                            </span>
                        ) : (null)}
                    </Label>
                ) : (null)}

                <div className={bem.e('input')}>
                    {inputWithIds}
                </div>

                {props.label && props.labelPosition === 'after' ? (
                    <Label
                        className={ClassNames(props.labelClassname, bem.e('label'), {
                            'u-screen-reader': props.labelHidden,
                            'has-instruction': props.instruction
                        })}
                        id={labelId}
                        htmlFor={inputId}
                    >
                        {props.label}

                        {this.props.instruction ? (
                            <span className={bem.e('instruction')}>
                                {this.props.instruction}
                            </span>
                        ) : (null)}
                    </Label>
                ) : (null)}
            </div>
        );
    }
}

FormElement.defaultProps = {
    labelPosition: 'before'
};

FormElement.propTypes = {
    input: PropTypes.string,
    children: PropTypes.element.isRequired,
    label: PropTypes.string.isRequired,
    labelClassname: PropTypes.string,
    labelPosition: PropTypes.oneOf(['before', 'after']),
    instruction: PropTypes.string,
    hasValue: PropTypes.bool
};

export default FormElement;
