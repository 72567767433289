import React from 'react'
import { calloutProps } from 'lib/propShapes'
import Button from 'components/global/Button'
import Canvas from 'components/global/Canvas'
import Container from 'components/layout/Container'
import Headline from 'components/global/Headline'
import ClassNames from 'classnames'
import BEM from 'lib/BEM'

const Callout = (props) => {
    const bem = new BEM('callout');

    return (
        <Canvas
            styleModifier = "alt"
            tick = {true}
        >
            <div className={ClassNames(bem.b, {
                [bem.m('full')]: !props.padded || props.full
            })}>
                <Container>
                    <Headline
                        {...props}
                    />

                    {props.cta ? (
                        <div className={bem.e('cta')}>
                            <Button
                                {...props.cta}
                                buttonStyle = "primary"
                            />
                        </div>
                    ) : (null)}
                </Container>
            </div>
        </Canvas>
    )
}

Callout.defaultProps = {
    subtitle: null,
    padded: true,
    full: false
}

Callout.propTypes = calloutProps

export default Callout
