import React from 'react'
import PropTypes from 'prop-types'
import { imageUrl } from 'lib/utils'

const PhotoStack = (props) => {
    const blockClassName = 'photo-stack';

    let _element = (name) => {
        return blockClassName + '__' + name;
    }

    let _EachPhoto = (photo, index) => (
        <div
            className = {_element('item')}
            key = {index}
        >
            <img
                src = {imageUrl(photo)}
                alt = ""
            />
        </div>
    )

    return (
        <div className={blockClassName}>
            {props.photos.map(_EachPhoto)}
        </div>
    )
}

PhotoStack.propTypes = {
    photos: PropTypes.arrayOf(PropTypes.string)
}

export default PhotoStack
