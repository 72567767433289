import React from 'react'
import BEM from 'lib/BEM'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'

import Container from 'components/layout/Container'

import { COLOR_BRAND } from '../../../constants'

const Blockquote = (props) => {
    const bem = new BEM('content-block-blockquote');

    return (
        <div className={ClassNames(bem.b, props.className)}>
            <Container>
                <div className={bem.e('body')}>
                    {props.body}
                </div>
                <div className={bem.e('divider')} style={{background: props.brandColor}} />
            </Container>
        </div>
    )
}

Blockquote.defaultProps = {
    brandColor: COLOR_BRAND
}

Blockquote.propTypes = {
    body: PropTypes.string.isRequired,
    brandColor: PropTypes.string
}

export default Blockquote
