import React from 'react'
import BEM from 'lib/BEM'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'

import Container from 'components/layout/Container'
import Slider from 'components/photo/Slider'

import { imageUrl } from 'lib/utils'
import { COLOR_BRAND } from '../../../constants'

const Content = (props) => {
    const bem = new BEM('content-block-content'),
          hasImages = props.images && props.images.length;

    return (
        <div className={ClassNames(bem.b, props.className, {
            'has-images': hasImages,
            [`has-images--${props.images.length}`]: hasImages
        })}>
            <Container className={bem.e('container')}>
                <div className={bem.e('main')}>
                    <h4 className={bem.e('title')} style={{color: props.brandColor}}>
                        {props.title}
                    </h4>
                    <div className={bem.e('body')} dangerouslySetInnerHTML={{__html: props.body}} />
                </div>
                { hasImages ? (
                    <div className={bem.e('images')}>
                        { props.images.length > 1 ? (
                            <Slider
                                photos={props.images.map(image => imageUrl(image))}
                                toShowAtLarge={1}
                                toShowAtSmall={1}
                                className={bem.e('slider')}
                                arrowColor={props.brandColor}
                                infinite={true}
                            />
                        ) : (
                            <img
                                src={imageUrl(props.images[0])}
                                alt=""
                                className={bem.e('lonely-image')}
                            />
                        ) }
                    </div>
                ) : (null) }
            </Container>
        </div>
    )
}

Content.defaultProps = {
    brandColor: COLOR_BRAND
}

Content.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string),
    brandColor: PropTypes.string
}

export default Content
