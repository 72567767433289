import React from 'react'
import Button from 'components/global/Button'
import ClassNames from 'classnames'
import BEM from 'lib/BEM'
import { caseStudyTileProps } from 'lib/propShapes'
import { imageUrl } from 'lib/utils'

const CaseStudyFeature = (props) => {
    const bem = new BEM('case-study-feature');

    let _foregroundStyle = {},
        _backgroundStyle = {},
        _foregroundClass = bem.e('foreground')

    if (props.image.foregroundPosition) {
        _foregroundStyle = Object.assign(props.image.foregroundPosition, _foregroundStyle);
    }

    if (props.image.background) {
        _backgroundStyle.background = props.image.background;
    }

    if (props.image.backgroundOffset) {
        _backgroundStyle.transform = `translateX(${props.image.backgroundOffset})`;
    }

    if (props.image.foregroundPositionRule) {
        _foregroundClass += ` ${bem.e('foreground--' + props.image.foregroundPositionRule)}`
    }

    return (
        <div className={ClassNames(bem.b)}>
            <div className={bem.e('imagery')}>
                {props.image.foreground ? (
                    <img
                        src = {imageUrl(props.image.foreground)}
                        alt = {props.title}
                        className = {_foregroundClass}
                        style = {_foregroundStyle}
                    />
                ) : (null)}

                {props.image.photo ? (
                    <div className={bem.e('background')}>
                        <img
                            src = {imageUrl(props.image.photo)}
                            alt = {props.title}
                            className = {bem.e('photo-actual')}
                            style = {_backgroundStyle}
                        />
                    </div>
                ) : (null)}

                {props.image.background ? (
                    <div
                        className={bem.e('background')}
                        style = {_backgroundStyle}
                    />
                ) : (null)}
            </div>
            <div className={bem.e('body')}>
                <h4 className={bem.e('title')} style={{color: props.brandColor}}>
                    {props.title}
                </h4>
                {props.category ? (
                        <span className={bem.e('category')}>
                            {props.category}
                        </span>
                    ) : (null)}
                <div className={bem.e('description')}>
                    {props.description}
                </div>
                {props.cta ? (
                    <div className={bem.e('cta')} >
                        <Button
                            {...props.cta}
                            buttonStyle = "underline"
                            brandColor={props.brandColor}
                        />
                    </div>
                ) : (null)}
            </div>
        </div>
    )
}

CaseStudyFeature.propTypes = caseStudyTileProps

export default CaseStudyFeature
