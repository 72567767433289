import React from 'react'
import { connect } from 'react-redux'
import { imageUrl } from 'lib/utils'
import Container from 'components/layout/Container'
import classNames from 'classnames'

const HowVenn = (props) => {
    const blockClassName = 'how-venn';

    let _element = (name) => {
        return blockClassName + '__' + name;
    }

    return (
        <div className={blockClassName}>
            <Container>
                <div className={_element('text')}>
                    <h3 className={classNames(
                        _element('title'),
                        't-h2'
                    )}>
                        {props.title}
                    </h3>
                    <div className="t-large-body">
                        {props.description}
                    </div>
                </div>
                <div className={classNames(
                    't-large-body',
                    _element('diagram')
                )}>
                    <img
                        src = {imageUrl(props.diagram)}
                        alt = ""
                    />
                </div>
            </Container>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state.how.venn
}

export default connect(mapStateToProps, null)(HowVenn)
