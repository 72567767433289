import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { imageUrl } from 'lib/utils'
import breakpoints from 'lib/breakpoints'
import MediaQuery from 'react-responsive'

import Container from 'components/layout/Container'
import Canvas from 'components/global/Canvas'
import Headline from 'components/global/Headline'

const AgileOverview = (props) => {
    const blockClassName = 'agile-overview';

    let _element = (name) => {
        return blockClassName + '__' + name;
    }

    return (
        <div className={blockClassName}>
            <Container className="l-small-full">
                <Canvas styleModifier="gradient">
                    <div className={_element('inner')}>
                        <Headline
                            title = {props.title}
                            description = {props.description}
                        >
                            <div className={_element('diagram')}>
                                <MediaQuery minWidth={breakpoints.mid + 1}>
                                    <img
                                        src = {imageUrl(props.diagram.large)}
                                        alt = ""
                                    />
                                </MediaQuery>
                                <MediaQuery maxWidth={breakpoints.mid}>
                                    <img
                                        src = {imageUrl(props.diagram.small)}
                                        alt = ""
                                    />
                                </MediaQuery>
                            </div>
                        </Headline>
                    </div>
                </Canvas>
            </Container>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state.how.process
}

AgileOverview.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    diagram: PropTypes.shape({
        large: PropTypes.string,
        small: PropTypes.string
    })
}

export default connect(mapStateToProps, null)(AgileOverview)
