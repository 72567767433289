import React from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'

import Container from 'components/layout/Container'
import SVGInline from 'react-svg-inline'
import breakpoints from 'lib/breakpoints'
import Slick from 'react-slick'
import MediaQuery from 'react-responsive'
import classNames from 'classnames'

import { imageUrl } from 'lib/utils'
import { COLOR_BRAND } from '../../constants'

import LeftIcon from 'images/icons/arrow-left.svg'
import RightIcon from 'images/icons/arrow-right.svg'


export default class Slider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            atStart: true,
            atEnd: false
        }
    }

    _PreviousArrow = (props) => {
        return (
            <button
                className = {classNames(
                    'slider__arrow', 'slider__arrow--previous', 'button--symantic', {
                        'is-disabled': this.state.atStart
                    }
                )}
                onClick = {props.onClick}
            >
                <SVGInline
                    svg = {LeftIcon}
                    className = "slider__arrow__svg"
                    fill = {props.color}
                    cleanup = {true}
                />
            </button>
        )
    }

    _NextArrow = (props) => {
        return (
            <button
                className = {classNames(
                    'slider__arrow', 'slider__arrow--next', 'button--symantic', {
                        'is-disabled': this.state.atEnd
                    }
                )}
                onClick = {props.onClick}
            >
                <SVGInline
                    svg = {RightIcon}
                    className = "slider__arrow__svg"
                    fill = {props.color}
                    cleanup = {true}
                />
            </button>
        )
    }

    _Slide = (photo, index) => (
        <div
            key = {index}
            className = "slider__item"
        >
            <img
                src = {imageUrl(photo)}
                alt = ""
            />
        </div>
    )

    _handleSlideChange = (index, toShow) => {
        let atStart = index === 0 ? true : false,
            atEnd = index + toShow >= this.props.photos.length ? true : false;

        this.setState({
            atStart: atStart,
            atEnd: atEnd
        })
    }

    _Slider = (props) => (
        <Slick
            className = {ClassNames('slider', this.props.className, {
                'slider--infinite': this.props.infinite,
                'slider--finite': !this.props.infinite,
            })}
            dots = {false}
            infinite = {props.infinite}
            slidesToShow = {props.toShow}
            slidesToScroll = {1}
            variableWidth = {true}
            focusOnSelect = {true}
            beforeChange = {(oldIndex, newIndex) => this._handleSlideChange(newIndex, props.toShow)}
            nextArrow = {<this._NextArrow color={props.arrowColor} />}
            prevArrow = {<this._PreviousArrow color={props.arrowColor} />}
        >
            {this.props.photos.map(this._Slide)}
        </Slick>
    )

    render() {
        return (
            <Container>
                <MediaQuery query={`(max-width: ${breakpoints.small}px)`}>
                    <this._Slider
                        toShow={this.props.toShowAtSmall}
                        arrowColor={this.props.arrowColor}
                        infinite={this.props.infinite}
                    />
                </MediaQuery>
                <MediaQuery query={`(min-width: ${(breakpoints.small + 1)}px)`}>
                    <this._Slider
                        toShow={this.props.toShowAtLarge}
                        arrowColor={this.props.arrowColor}
                        infinite={this.props.infinite}
                    />
                </MediaQuery>
            </Container>
        )
    }
}

Slider.defaultProps = {
    toShowAtSmall: 1,
    toShowAtLarge: 3,
    arrowColor: COLOR_BRAND
}

Slider.propTypes = {
    photos: PropTypes.arrayOf(PropTypes.string),
    toShowAtSmall: PropTypes.number,
    toShowAtLarge: PropTypes.number,
    arrowColor: PropTypes.string,
    infinite: PropTypes.bool
}
