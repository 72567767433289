import React from 'react'
import PropTypes from 'prop-types'
import { imageUrl } from 'lib/utils'

const PhotoStrew = (props) => {
    const blockClassName = 'photo-strew';

    let _element = (name) => {
        return blockClassName + '__' + name;
    }

    let PhotoStrewPhoto = (photo, index) => (
        <img
            src = {imageUrl(photo)}
            className = {_element('photo')}
            alt = ""
            key = {index}
        />
    )

    return (
        <div className={blockClassName}>
            <div className={_element('left-column')}>
                {props.photos.slice(0, 2).map(PhotoStrewPhoto)}
            </div>
            <div className={_element('right-column')}>
                {props.photos.slice(2).map(PhotoStrewPhoto)}
            </div>
        </div>
    )
}

PhotoStrew.propTypes = {
    photos: PropTypes.arrayOf(
        PropTypes.string
    ).isRequired
}

export default PhotoStrew
