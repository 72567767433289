import React from 'react'
import BEM from 'lib/BEM'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'

import Container from 'components/layout/Container'
import Slider from 'components/photo/Slider'

const ContentSlider = (props) => {
    const bem = new BEM('content-block-slider');

    return (
        <div className={ClassNames(bem.b, props.className)}>
            <Container className={bem.e('inner')}>
                <Slider
                    className={bem.e('slider')}
                    photos={props.images}
                    toShowAtSmall={1}
                    toShowAtLarge={1}
                    arrowColor={props.brandColor}
                    infinite={true}
                />
            </Container>
        </div>
    )
}

Slider.propTypes = {
    images: PropTypes.arrayOf(PropTypes.string)
}

export default ContentSlider
