import React from 'react'
import PropTypes from 'prop-types'
import { imageUrl } from 'lib/utils'
import ClassNames from 'classnames'
import BEM from 'lib/BEM'
import Button from 'components/global/Button'

const CapabilitiesIntro = (props) => {
    const bem = new BEM('capabilities-intro');
    let _eachCapability = (item, index) => (
        <li
            className = {bem.e('item')}
            key = {index}
        >
            <img className={bem.e('logo')} src = {imageUrl(item.logo)} alt = {item.title}/>
            <div className={bem.e('body')}>
                <h4 className={bem.e('title')}>
                    {item.title}
                </h4>
                <div className={bem.e('description')}>
                    {item.description}
                </div>
                {item.cta && 
                    <div className={bem.e('cta')}>
                        <Button
                            {...item.cta}
                            buttonStyle = "underline"
                        />
                    </div>
                }
                
            </div>

        </li>
    )

    return (
        <ul className={ClassNames(bem.b, props.className)}>
            {props.capabilities.map(_eachCapability)}
        </ul>
    )
}

CapabilitiesIntro.propTypes = {
    capabilities: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            cta: PropTypes.string,
            logo: PropTypes.string
        })
    ).isRequired
}

export default CapabilitiesIntro
