import A from 'reduxStore/actionTypes'
import { combineReducers } from 'redux'

export const chat = (state = false, action) => {
    return action.type === A.CHAT__TOGGLE ? action.payload : state;
}

export const mobileNav = (state = false, action) => {
    return action.type === A.MOBILE_NAV__TOGGLE ? action.payload : state;
}

export const headerSpacer = (state = true, action) => {
    return action.type === A.HEADER__SET_SPACER ? action.payload : state;
}

export const headerHeight = (state = false, action) => {
    return action.type === A.HEADER__SET_HEIGHT ? action.payload : state;
}

export const assetHost = (state = {}, action) => {
    return action.type === A.CONFIG__ASSET_HOST ? action.payload : state;
}

export const pageYOffset = (state = 0, action) => {
    return action.type === A.SCROLL__Y_OFFSET ? action.payload : state;
}

export const workingForm = (state = null, action) => {
    return action.type === A.FORM__WORKING ? action.payload : state;
}

export const submittedForms = (state = [], action) => {
    if (action.type === A.FORM__SUBMITTED) {
        let newState = [];
        state.forEach((item) => {
            newState.push(item);
        });
        newState.push(action.payload.formId)
        return newState;
    } else {
        return state;
    }
}

export const passThrough = (state = false, action) => {
    return state;
}

export default combineReducers({
    presentation: combineReducers({
        chat: chat,
        mobileNav: mobileNav,
        headerSpacer: headerSpacer,
        headerHeight: headerHeight
    }),
    config: combineReducers({
        assetHost: assetHost
    }),
    pageYOffset: pageYOffset,
    workingForm: workingForm,
    submittedForms: submittedForms,
    menu: passThrough,
    footerMenu: passThrough,
    home: passThrough,
    about: passThrough,
    how: passThrough,
    careers: passThrough,
    contact: passThrough,
    results: passThrough,
    capabilities: passThrough,
    // web3: passThrough,
    // b2b: passThrough,
    // marketplaces: passThrough,
    // ideaAccelerator: passThrough,
    globalContent: passThrough,
    caseStudies: passThrough,
    lastAction: function(state = null, action) {
        return action;
    }
})
