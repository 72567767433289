import React from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'

const Form = (props) => {
    const _handleSubmit = (event) => {
        if (props.onSubmit) {
            props.onSubmit(event);
            event.preventDefault();
        }
    };

    const _handleChange = (event) => {
        if (props.onChange) {
            props.onChange(event);
        }
    };

    return (
        <form
            onChange={(event) => _handleChange(event)}
            className={ClassNames('form', props.className)}
            onSubmit={(event) => _handleSubmit(event)}
        >
            {props.children}
        </form>
    );
};

Form.propTypes = {
    children: PropTypes.any.isRequired,
    onSubmit: PropTypes.func,
    onChange: PropTypes.func
};

export default Form;
