import React from 'react'
import PropTypes from 'prop-types'
import { imageUrl } from 'lib/utils'

const CircledImage = (props) => {
    const blockClassName = 'circled-image';

    let _element = (name) => {
        return blockClassName + '__' + name;
    }

    return (
        <div className={blockClassName}>
            <img 
                src = {imageUrl(props.image)} 
                alt = {props.title} 
                className = {_element('image')} />
        </div>
    )
}

CircledImage.propTypes = {
    image: PropTypes.string.isRequired
}

export default CircledImage
