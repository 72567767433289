import React from 'react'
import PropTypes from 'prop-types'
import BEM from 'lib/BEM'
import { ctaProps, caseStudyTileProps } from 'lib/propShapes'

import Canvas from 'components/global/Canvas'
import Button from 'components/global/Button'
import Container from 'components/layout/Container'
import Collection from 'components/layout/Collection'
import CaseStudyFeature from 'components/caseStudies/CaseStudyFeature'
import Headline from 'components/global/Headline'

const FeaturedCaseStudies = (props) => {
    const bem = new BEM('featured-case-studies');

    return (
        <Canvas
            styleModifier = "gradient"
            tick = {true}
        >
            <div className={bem.b}>
                <Container className="l-mid-full">
                    <div className={bem.e('headline')}>
                        <Headline title={props.title} />
                    </div>

                    <Collection
                        items = {props.items}
                        component = {CaseStudyFeature}
                        className = {bem.e('list')}
                    />

                    <div className={bem.e('cta')}>
                        <Button {...props.cta} />
                    </div>
                </Container>
            </div>
        </Canvas>
    )
}

FeaturedCaseStudies.propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape(caseStudyTileProps)
    ).isRequired,
    cta: PropTypes.shape(ctaProps)
}

export default FeaturedCaseStudies
