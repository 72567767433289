import React from 'react'
import PropTypes from 'prop-types'
import { imageUrl } from 'lib/utils'
import BEM from 'lib/BEM'
import ClassNames from 'classnames'

const Canvas = (props) => {
    const bem = new BEM('canvas');

    let style = {};

    if (props.backgroundPhoto) {
        style.backgroundImage = `url(${imageUrl(props.backgroundPhoto)})`
    }

    return (
        <div style={style} className={ClassNames(bem.b, props.className, {
            'tick': props.tick,
            [`tick--${props.tickStyle}`]: props.tickStyle,
            [bem.m('alt')]: props.alt,
            [bem.m('gradient')]: props.gradient,
            [bem.m('offset')]: props.offset,
            [bem.m('background-photo')]: props.backgroundPhoto,
            [bem.m(props.styleModifier)]: props.styleModifier
        })}>
            {props.children}
        </div>
    )
}

Canvas.defaultProps = {
    tick: false
}

Canvas.propTypes = {
    styleModifier: PropTypes.string,
    alt: PropTypes.bool,
    gradient: PropTypes.bool,
    offset: PropTypes.bool,
    tick: PropTypes.bool,
    tickStyle: PropTypes.string
}

export default Canvas
