import React from 'react'
import { connect } from 'react-redux'
import Container from 'components/layout/Container'
import classNames from 'classnames'
import List from 'components/content/List'

const CareersOutlineItem = (props) => {
    const blockClassName = 'careers-outline-item';

    let _element = (name) => {
        return blockClassName + '__' + name;
    }

    return (
        <div className={blockClassName}>
            <Container>
                <div className={_element('text')}>
                    <h3 className={classNames(
                        _element('title'),
                        't-h2'
                    )}>
                        {props.title}
                    </h3>
                    <div className={_element('body')}>
                        <div className={classNames(
                            't-large-body',
                            _element('description')
                        )}>
                            {props.description}
                        </div>
                        <div className={_element('points')}>
                            <List
                                items={props.points}
                            />
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state.careers.process
}

export default connect(mapStateToProps, null)(CareersOutlineItem)

