import React from 'react'
import PropTypes from 'prop-types'
import BEM from 'lib/BEM'
import ClassNames from 'classnames'

const Space = (props) => {
    const bem = new BEM('space');

    return (
        <div className={ClassNames(bem.b, {
            [bem.m(props.amount)]: props.amount,
            [bem.m('more')]: props.more,
            [bem.m('less')]: props.less,
            [bem.m('little')]: props.little,
            [bem.m('inside')]: props.inside
        })}>
            {props.children}
        </div>
    )
}

Space.propTypes = {
    amount: PropTypes.string,
    more: PropTypes.bool,
    less: PropTypes.bool,
    little: PropTypes.bool,
    inside: PropTypes.bool
}

export default Space
