import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'

import SVGInline from 'react-svg-inline'

import ProgressBar from 'components/global/ProgressBar'

import { imageUrl } from 'lib/utils'

import LeftIcon from 'images/icons/arrow-left.svg'
import RightIcon from 'images/icons/arrow-right.svg'

export default class HomeHeroSlider extends React.Component {
    constructor(props) {
        super(props);

        this.featuresRef = React.createRef()

        this.state = {
            index: 0,
            prevIndex: 0,
            timer: 0,
            seen: false,
            delay: false,
        }

        this._startTransition = this._startTransition.bind(this)
        this._nextFeature = this._nextFeature.bind(this)
        this._prevFeature = this._prevFeature.bind(this)
        this._isVisible = this._isVisible.bind(this)
        this._featuresSeen = this._featuresSeen.bind(this)
    }

    _startTransition() {
        this.setState({
            timer: 0,
        })
        this.tick = setInterval(() => {
            if (this.state.paused || !this.state.seen) {
                this.setState({
                    timer: 0,
                })
                return
            } this.setState({
                timer: this.state.timer + 1
            })
            if (this.state.timer / 60 > this.props.interval) {
                this.setState({
                    timer: 0,
                })
                this._nextFeature()
            }}, 16.33);
    }

    _isVisible(ele) {
        if (ele) var { top, bottom } = ele.getBoundingClientRect();
        const vHeight = (window.innerHeight || document.documentElement.clientHeight);
        if ((top > 0 || bottom > 0) && top < vHeight) {
            return true
        } else {
            return false
        }
    }

    _featuresSeen() {
        if (this._isVisible(this.featuresRef.current)) {
            this.setState({seen: true});
            window.removeEventListener('scroll', this._featuresSeen);
        }
    }

    componentDidMount = () => {
        this.props.features.forEach(({image}) => {
            const img = new Image();
            img.src = imageUrl(image);
        });
        setTimeout(function(){
            if (this._isVisible(this.featuresRef.current)) {
                this.setState({ seen: true })    
            } else {
                window.addEventListener('scroll', this._featuresSeen, true);
            }
        }.bind(this), 500);
        this._startTransition()
    }

    componentWillUnmount = () => {
        if (!this.state.seen) {
            window.removeEventListener('scroll', this._featuresSeen);
        }
    }

    _nextFeature = () => {
        if (this.state.delay) {
            return
        } else {
            this.setState({delay: true})
            setTimeout(() => this.setState({delay: false}), 400);
        }
        if (this.state.index === this.props.features.length) {
            this.setState({
                prevIndex: this.state.index,
                index: 0,
            })
        } else {
            this.setState({
                prevIndex: this.state.index,
                index: this.state.index + 1,
            })
        }
    }

    _prevFeature = () => {
        if (this.state.delay) {
            return
        } else {
            this.setState({delay: true})
            setTimeout(() => this.setState({delay: false}), 400);
        }
        if (this.state.index < 1) {
            this.setState({
                prevIndex: this.state.index,
                index: this.props.features.length,
            })
        } else {
            this.setState({
                prevIndex: this.state.index,
                index: this.state.index - 1,
            })
        }
    }

    _PreviousArrow = (props) => {
        return (
            <button
                className = 'arrows__prev'
                onClick = {props.onClick}
            >
                <SVGInline
                    svg = {LeftIcon}
                    className = "slider__arrow__svg"
                    fill = {props.color}
                    cleanup = {true}
                />
            </button>
        )
    }

    _NextArrow = (props) => {
        return (
            <button
                className='arrows__next'
                onClick = {props.onClick}
            >
                <SVGInline
                    svg = {RightIcon}
                    className = "slider__arrow__svg"
                    fill = {props.color}
                    cleanup = {true}
                />
            </button>
        )
    }

    _updateIndex = () => {

    }

    render() {
        const { defaults, features, interval } = this.props
        const { index, prevIndex, timer } = this.state

        const placeholderBgImage = prevIndex === 0 ? imageUrl(defaults.image) : imageUrl(features[prevIndex - 1].image) 

        return (
            <>
                <ProgressBar
                    current={this.state.index}
                    total={features.length}
                    percentage={(((timer / 60) * 100) * 1.3 / interval) - 15}
                />
                <div className={'home-hero__placeholder'} style={{ backgroundImage: `url(${placeholderBgImage})`}}>
                    <img
                        key={index}
                        className={index === prevIndex ? 'home-hero__image' : 'home-hero__image --animate'}
                        src={index === 0 ? imageUrl(defaults.image) : imageUrl(features[index - 1].image) }
                        alt={index === 0 ? "What Comes Next GIF" : features[index-1].title}
                    />
                </div>
                <div
                    className={'home-hero__features'}
                    ref={this.featuresRef}
                >
                    <div className={classNames('home-hero__default', {'--active': index === 0})}>
                        <h3>
                            {defaults.description}
                        </h3>
                    </div>
                    {features.map(({title, description, link}, idx) => {
                        const cn = index === 0 ? '' : index - 1 === idx ? '--active' : '--inactive'
                        return (
                            <a
                                key={title}
                                href={link}
                                target={link.startsWith('./') ? '' : '_blank'}
                                className={cn}
                                onMouseOver={() => {
                                    if (index - 1 !== idx) {
                                        this.setState({prevIndex: index, index: idx + 1, timer: 0, paused: true})
                                    } else {
                                        this.setState({timer: 0, paused: true})
                                    }
                                }}
                                onMouseLeave={() => this.setState({paused: false})}
                            >
                                <h4 className='home-hero__feature-title'>{title}</h4>
                                <p className='home-hero__feature-description'>{description}</p>
                            </a>
                    )
                    })}
                </div>
                <div className={classNames('home-hero__arrows', {'--with-bumper': index === 0})}>
                    <div className={'home-hero__arrows-inner'}>
                        <this._PreviousArrow onClick={() => { if (!this.state.delay) this._prevFeature(); this.setState({ timer: 0 })}} />
                        <this._NextArrow onClick={() => { if (!this.state.delay) this._nextFeature(); this.setState({ timer: 0 })}} />
                    </div>
                </div>
            </>
        )
    }
}

HomeHeroSlider.propTypes = {
    defaults: PropTypes.shape({
        description: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
    }).isRequired,
    features: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
    })).isRequired,
    interval: PropTypes.number.isRequired
}
