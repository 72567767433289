import React, { useState } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import store from 'reduxStore'
import { setMobileNavVisibility } from 'reduxStore/actions'

const Dropdown = (props) => {
    const [click, setClick] = useState(false);
    let toggleSubmenu = () => {
        setClick(click === false ? true : false);
    }

    let _closeMobileNav = () => {
        store.dispatch(setMobileNavVisibility(false))
    }

    return (
            <ul className={`dropdown ${props.dropdown ? "show" : ""}`} onClick={toggleSubmenu}>
                {props.dropdown.map((dropdown, index) => (
                <li key={index} className="dropdown__item">
                    <NavLink
                        to = {dropdown.href}
                        onClick = {_closeMobileNav}
                        activeClassName = "is-active"
                    >   
                        <span className={'dropdown__label'}>
                            {dropdown.label}
                        </span>
                    </NavLink>
                    
                </li>
                ))}
        </ul>
    );

}

const mapStateToProps = (state) => {
    return {
        items: state.menu.dropdown
    }
}

export default connect(mapStateToProps, null)(Dropdown)