import React from 'react'
import ClassNames from 'classnames'
import BEM from 'lib/BEM'
import { connect } from 'react-redux'
import { imageUrl, preloadImages } from 'lib/utils'

export class ResultsScreenshots extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            triggerAnimation: false
        }
    }

    componentDidMount() {
        let imageUrls = [];

        this.props.screenshots.forEach((screenshot) => {
            imageUrls.push(imageUrl(screenshot));
        })

        preloadImages(imageUrls)
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                this.setState({
                    triggerAnimation: true
                })
            })
    }

    render() {
        const bem = new BEM('results-screenshots');

        return (
            <div className={ClassNames(bem.b, this.props.className, {
                'is-ready': this.state.triggerAnimation
            })}>
                { this.props.screenshots.map((screenshot, index) => (
                    <img
                        src = {imageUrl(screenshot)}
                        key = {index}
                        className = {ClassNames(bem.e('item'), bem.e(`item--${index + 1}`))}
                        alt=''
                    />
                )) }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        screenshots: state.results.hero.screenshots
    }
}

export default connect(mapStateToProps, null)(ResultsScreenshots)
