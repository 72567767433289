import React from 'react'
import BEM from 'lib/BEM'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'

import Container from 'components/layout/Container'

import { COLOR_BRAND } from '../../../constants'
import { imageUrl } from 'lib/utils'

const Expose = (props) => {
    const bem = new BEM('content-block-expose');

    return (
        <div
            className={ClassNames(bem.b, props.className)}
            style={{backgroundColor: props.brandColor}}
        >
            <div
                className={bem.e('background-image')}
                style={{backgroundImage: `url(${imageUrl(props.backgroundPhoto)})`}}
            />
            <Container>
                <div className={bem.e('body')}>
                    <p className={bem.e('title')}>
                        {props.title}
                    </p>
                    <div className={bem.e('foreground-image')}>
                        <img
                            className={bem.e('foreground-image-actual')}
                            src={imageUrl(props.transparentPngForeground)}
                            alt=""
                        />
                    </div>
                </div>
            </Container>
        </div>
    )
}

Expose.defaultProps = {
    brandColor: COLOR_BRAND
}

Expose.propTypes = {
    brandColor: PropTypes.string,
    title: PropTypes.string.isRequired,
    backgroundPhoto: PropTypes.string.isRequired,
    transparentPngForeground: PropTypes.string.isRequired
}

export default Expose
