import React from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'
import BEM from 'lib/BEM'

export const Enemies = (props) => {
    const bem = new BEM('enemies');

    let filteredChildren = props.children.length ? props.children.filter((el) => el !== null) : props.children;
    filteredChildren = filteredChildren.length === 1 ? filteredChildren[0] : filteredChildren;

    let rightIndex = filteredChildren.length === 3 ? 2 : 1;

    return (
        <div className={ClassNames(bem.b, props.className, {
            [bem.m('one-only')]: !filteredChildren.length
        })}>
            {filteredChildren[0] ? (
                <div className={bem.e('left')}>
                    {filteredChildren[0]}
                </div>
            ) : (null)}

            {filteredChildren.length === 3 ? (
                <div className={bem.e('center')}>
                    {filteredChildren[1]}
                </div>
            ) : (null)}

            {filteredChildren.length > 1 ? (
                <div className={bem.e('right')}>
                    {filteredChildren[rightIndex]}
                </div>
            ) : (null)}

            {!filteredChildren.length ? (
                <div className={bem.e('loaner')}>
                    {filteredChildren}
                </div>
            ) : (null)}
        </div>
    );
};

Enemies.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.element).isRequired
    ])
};

export default Enemies;
