import React from 'react'
import throttle from 'lodash.throttle'
import { connect } from 'react-redux'

import { setYOffset } from 'reduxStore/actions'

class ScrollWatcher extends React.Component {
    componentDidMount() {
        window.addEventListener('scroll', throttle(() => {
            this.props.setYOffset(window.pageYOffset)
        }, 100));
    }

    render() {
        return this.props.children || null;
    }
}

const mapDispatchToProps = (dispatch) => ({
    setYOffset: (offsett) => {
        dispatch(setYOffset(offsett))
    }
})

export default connect(null, mapDispatchToProps)(ScrollWatcher)
