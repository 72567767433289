import React from 'react'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'
import BEM from 'lib/BEM'
import ClassNames from 'classnames'

import Hero from 'components/hero/Hero'
import PhotoStack from 'components/photo/PhotoStack'
import TabbedShowcase from 'components/content/TabbedShowcase'
import HowClientTabs from 'components/partials/HowClientTabs'
import HowVenn from 'components/partials/HowVenn'
import AgileOverview from 'components/partials/AgileOverview'
import HowOutline from 'components/partials/HowOutline'
import HowTeam from 'components/partials/HowTeam'
import Callout from 'components/global/Callout'

const About = (props) => {
    const bem = new BEM('page');

    return (
        <DocumentTitle title="How We Work | Invoke">
            <div className={ClassNames(bem.b, bem.m('how'))}>
                <Hero
                    {...props.hero}
                >
                    <PhotoStack photos={props.hero.photos} />
                </Hero>
                <TabbedShowcase {...props.clients}>
                    <HowClientTabs />
                </TabbedShowcase>
                <HowVenn />
                <AgileOverview />
                <HowOutline />
                <HowTeam />
                <Callout
                    {...props.callout}
                    padded = {false}
                />
            </div>
        </DocumentTitle>
    )
}

const mapStateToProps = (state) => {
    return state.how
}

export default connect(mapStateToProps, null)(About)
