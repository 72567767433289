import React from 'react'
import PropTypes from 'prop-types';

const ActiveButton = (props) => {
    const blockClassName = 'active-button';
    let classString = blockClassName;

    if (props.className) {
        classString += ` ${props.className}`;
    }

    if (props.isActive) {
        classString += ' is-active';
    }

    let _element = (name) => {
        return blockClassName + '__' + name;
    }

    return (
        <button
            className = {classString}
        >
            <div className = {_element('spinner')}>
                <div className="spinner" />
            </div>
            <span className = {_element('label')}>
                {props.label}
            </span>
        </button>
    )
}

ActiveButton.defaultProps = {
    isActive: false,
    type: 'button'
}

ActiveButton.propTypes = {
    isActive: PropTypes.bool,
    className: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string.isRequired
}

export default ActiveButton
