import React from 'react'
import { connect } from 'react-redux'
import Logo from 'components/global/Logo'
import Canvas from 'components/global/Canvas'
import Container from 'components/layout/Container'
import ContactInfo from 'components/global/ContactInfo'
import Social from 'components/global/Social'
import UtilityMenu from 'components/global/UtilityMenu'
import Signup from 'components/global/Signup'
import { Columns, Column } from 'components/layout/Columns'

const Footer = (props) => {
    const blockClassName = 'site-footer';

    let _element = (name) => {
        return blockClassName + '__' + name;
    }

    return (
        <Canvas styleModifier="gradient--dark">
            <div className={`${blockClassName} u-reverse`}>
                <Container className="l-small-full">
                    <Columns count={3}>
                        <Column>
                            <div className={`${_element('section')} ${_element('invoke')}`}>
                                <div className={_element('logo')}>
                                    <div className={_element('header')}>
                                        <Logo fill='#fff' />
                                    </div>
                                </div>
                                <div className={_element('contact')}>
                                    <ContactInfo />
                                </div>
                            </div>
                        </Column>

                        <Column>
                            <div className={_element('section')}>
                                <div className={_element('social')}>
                                    <div className={_element('header')}>Follow us on:</div>
                                    <Social />
                                    <UtilityMenu className={_element('utility-menu')} />
                                </div>
                            </div>
                        </Column>

                        <Column>
                            <div className={_element('section')}>
                                <div className={_element('header')}>
                                    Get inspiration in your inbox every month
                                </div>
                                <Signup />
                            </div>
                        </Column>
                    </Columns>
                </Container>
            </div>
        </Canvas>
    )
}

export default connect()(Footer)
