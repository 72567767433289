import React from 'react'
import PropTypes from 'prop-types'
import Container from 'components/layout/Container'
import Index from 'components/content/Index'

const CareersOutline = (props) => {
    const blockClassName = 'careers-outline';

    let _element = (name) => {
        return blockClassName + '__' + name;
    }

    return (
        <div className={blockClassName}>
            <Container>
                <div className={_element('intro')}>
                    <h2 className={_element('title')}>
                        {props.title}
                    </h2>
                    <div className={_element('description')}>
                        {props.description}
                    </div>
                </div>
                <div className={_element('index')}>
                    <Index items={props.points} />
                </div>
            </Container>
        </div>
    )
}

CareersOutline.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    points: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.title,
            description: PropTypes.description
        })
    )
}

export default CareersOutline
