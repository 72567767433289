import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Index = (props) => {
    const blockClassName = 'list';
    let modifiers = '';

    let _element = (name) => {
        return blockClassName + '__' + name;
    }

    let _modifier = (name) => {
        return blockClassName + '--' + name;
    }

    let ListItem = (item, index) => (
        <li
            className = {_element('item')}
            key = {index}
        >
            {item}
        </li>
    )

    if (props.listStyle) {
        modifiers += _modifier(props.listStyle);
    }

    return (
        <ul className={classNames(
            blockClassName,
            modifiers
        )}>
            {props.items.map(ListItem)}
        </ul>
    )
}

Index.propTypes = {
    itmes: PropTypes.arrayOf(
        PropTypes.string
    ),
    listStyle: PropTypes.string
}

export default Index
