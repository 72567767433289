import 'react-app-polyfill/ie11'
import 'airbnb-browser-shims'
import React from 'react'
import ReactDOM from 'react-dom'
import App from 'App'
import { Provider } from 'react-redux'

import store from 'reduxStore'

const root = document.getElementById('invoke-v2')

if (root.hasChildNodes()) {
    ReactDOM.hydrate(
        <Provider store={store}>
            <App
                assetHost = {root.dataset.invokeAssetHost || null}
            />
        </Provider>,
        root
    );
} else {
    ReactDOM.render(
        <Provider store={store}>
            <App
                assetHost = {root.dataset.invokeAssetHost || null}
            />
        </Provider>,
        root
    );
}
