import React from 'react'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'
import BEM from 'lib/BEM'
import ClassNames from 'classnames'

import Hero from 'components/hero/Hero'
import SrcSet from 'components/global/SrcSet'
import ContactBusiness from '../components/partials/ContactBusiness'
import ContactNewsletter from 'components/partials/ContactNewsletter'
import ContactWork from 'components/partials/ContactWork'

import { imageUrl } from 'lib/utils'

const Contact = (props) => {
    const bem = new BEM('page');

    return (
        <DocumentTitle title="Get In Touch | Invoke">
            <div className={ClassNames(bem.b, bem.m('contact'))}>
                <Hero
                    {...props.hero}
                    className="contact-hero"
                    smallTitle
                    dark
                >
                    <SrcSet
                        url={imageUrl(props.hero.image)}
                        alt="Photo in Invoke office"
                        className="contact-hero__image"
                    />
                </Hero>
                <ContactBusiness />
                <ContactNewsletter />
                <ContactWork />
            </div>
        </DocumentTitle>
    )
}

const mapStateToProps = (state) => {
    return state.contact
}

export default connect(mapStateToProps, null)(Contact)
