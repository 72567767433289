import React from 'react'
import PropTypes from 'prop-types'
import SVGInline from 'react-svg-inline';

const CircledIcon = (props) => {
    const blockClassName = 'circled-icon';

    let _element = (name) => {
        return blockClassName + '__' + name;
    }

    return (
        <div className={blockClassName}>
            <SVGInline
                svg = {props.icon}
                cleanup = {true}
                className = {_element('icon')}
            />
        </div>
    )
}

CircledIcon.propTypes = {
    icon: PropTypes.string.isRequired
}

export default CircledIcon
