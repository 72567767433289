import React from 'react'
import BEM from 'lib/BEM'
import ClassNames from 'classnames'
import { connect } from 'react-redux'
import { contactProps } from 'lib/propShapes'

const ContactInfo = (props) => {
    const bem = new BEM('contact-info');

    return (
        <ul className={ClassNames(bem.b, props.className)}>
            <li className={ClassNames(bem.e('element'), bem.e('address1'))}>
                {props.address.line1}
            </li>
            <li className={ClassNames(bem.e('element'), bem.e('address2'))}>
                {props.address.line2}
            </li>
            <li className={ClassNames(bem.e('element'), bem.e('address3'))}>
                {props.address.line3}
            </li>
            <li className={ClassNames(bem.e('element'), bem.e('phone'))}>
                {props.phone}
            </li>
            <li className={ClassNames(bem.e('element'), bem.e('email'))}>
                <a href={`mailto:${props.email}`}>
                    {props.email}
                </a>
            </li>
        </ul>
    )
}

ContactInfo.propTypes = contactProps

const mapStateToProps = (state) => {
    return  state.globalContent.invoke.contact
}

export default connect(mapStateToProps, null)(ContactInfo)
