import React from 'react'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'
import BEM from 'lib/BEM'
import ClassNames from 'classnames'

import HomeHero from 'components/hero/HomeHero'
import BigQuote from 'components/content/BigQuote'
import FeaturedCaseStudies from 'components/caseStudies/FeaturedCaseStudies'
import Callout from 'components/global/Callout'
import Container from 'components/layout/Container'
import Canvas from 'components/global/Canvas'
import Headline from 'components/global/Headline'
import CapabilitiesIntro from 'components/partials/CapabilitiesIntro'

const Home = (props) => {
    const bem = new BEM('page')

    return (
        <DocumentTitle title="Invoke - Creating What Comes Next for Digital Products">
            <div className={ClassNames(bem.b, bem.m('home'))}>
                <HomeHero {...props.hero} />
                <Canvas className='home-capabilities-intro'>
                    <Container>
                        <Headline title={props.capabilitiesIntro.title} />
                        <CapabilitiesIntro
                            capabilities={props.capabilitiesIntro.capabilities}
                        />
                    </Container>
                </Canvas>
                <BigQuote {...props.quotes[0]} />
                <FeaturedCaseStudies {...props.caseStudies} />
                <Callout {...props.callout} />
            </div>
        </DocumentTitle>
    )
}

const mapStateToProps = (state) => {
    return state.home
}

export default connect(mapStateToProps, null)(Home)
