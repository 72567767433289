import PropTypes from 'prop-types'

export const ctaProps = {
    label: PropTypes.string,
    href: PropTypes.string
}

export const caseStudyTileProps = {
    title: PropTypes.string,
    description: PropTypes.string,
    cta: PropTypes.shape(ctaProps),
    image: PropTypes.shape({
        foreground: PropTypes.string,
        foregroundPosittion: PropTypes.object,
        foregroundPosittionRule: PropTypes.oneOf(['centter']),
        background: PropTypes.string,
        backgroundOffset: PropTypes.string
    })
}

export const calloutProps = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    cta: PropTypes.shape(ctaProps),
    padded: PropTypes.bool,
    full: PropTypes.bool
}

export const contactProps = {
    address: PropTypes.shape({
        line1: PropTypes.string.isRequired,
        line2: PropTypes.string.isRequired
    }).isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
}
