export default class BEM {
    constructor(block) {
        this.block = block;
    }

    get b() {
        return this.block;
    }

    e(element) {
        return `${this.block}__${element}`;
    }

    m(modifier) {
        return `${this.block}--${modifier}`;
    }
}
