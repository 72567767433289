import React from 'react'
import PropTypes from 'prop-types'

const Headline = (props) => {
    const blockClassName = 'headline';
    
    const _element = (name) => {
        if (props.capabilityPage) return blockClassName + '__' + name + '__capability';
        return blockClassName + '__' + name;
    }

    return (
        <div className={blockClassName}>
            {props.subtitle ? (
                <div className={_element('subtitle')}>
                    {props.subtitle}
                </div>
            ) : (null)}

            <h2 className={_element('title')}>
                {props.title}
            </h2>

            {props.description ? (
                <div className={_element('description')}>
                    {props.description}
                </div>
            ) : (null)}

            {props.children ? (
                <div className={_element('children')}>
                    {props.children}
                </div>
            ) : (null)}
        </div>
    )
}

Headline.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    description: PropTypes.string
}

export default Headline
