import React from 'react'
import { connect } from 'react-redux'
import BEM from 'lib/BEM'
import ClassNames from 'classnames'

import { imageUrl } from 'lib/utils'

import Canvas from 'components/global/Canvas'
import Container from 'components/layout/Container'
import Headline from 'components/global/Headline'
import Signup from 'components/global/Signup'

export const ContactNewsletter = (props) => {
    const bem = new BEM('contact-newsletter');

    return (
        <div className={ClassNames(bem.b, props.className)}>
            <Canvas offset alt>
                <Container>
                    <div className={bem.e('images')}>
                        <img src={imageUrl('images/contact/quote.jpg')} alt='Inspiration' />
                        <img src={imageUrl('images/contact/music.jpg')} alt='Inspiration' />
                        <img src={imageUrl('images/contact/drink.jpg')} alt='Inspiration' />
                    </div>
                    <Headline
                        title={props.title}
                        description={props.description}
                    >
                        <Signup className={bem.e('signup')} placeholder="Email address" />
                    </Headline>
                </Container>
            </Canvas>
        </div>
    )
}

const mapStateToProps = (state) => ({
    ...state.contact.newsletter
})

export default connect(mapStateToProps, null)(ContactNewsletter)
