import React from 'react'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'
import BEM from 'lib/BEM'
import ClassNames from 'classnames'

import Hero from 'components/hero/Hero'
import CareersOutlineItem from 'components/partials/CareersOutlineItem'
import BlockquoteFrieze from 'components/content/BlockquoteFrieze'
import CareersOutline from 'components/partials/CareersOutline'
import ContactCareers from 'components/partials/ContactCareers'
import StaggeredGallery from 'components/photo/StaggeredGallery'

const Careers = (props) => {
    const bem = new BEM('page');

    return (
        <DocumentTitle title="Careers | Invoke">
            <div className={ClassNames(bem.b, bem.m('careers'))}>
                <Hero
                    {...props.hero}
                    className = "careers-hero"
                    layout = "row"
                    dark
                >
                    <StaggeredGallery photos={props.hero.photos} />
                </Hero>
                <CareersOutlineItem />
                <BlockquoteFrieze {...props.opportunity} />
                <CareersOutline {...props.outline} />
                <ContactCareers />
            </div>
        </DocumentTitle>
    )
}

const mapStateToProps = (state) => {
    return state.careers
}

export default connect(mapStateToProps, null)(Careers)
