import React from 'react'
import PropTypes from 'prop-types'
import BEM from 'lib/BEM'
import ClassNames from 'classnames'

import Container from 'components/layout/Container'
import Narrow from 'components/layout/Narrow'
import SrcSet from 'components/global/SrcSet'

import { imageUrl } from 'lib/utils'

const bem = new BEM('case-study-intro');

const CaseStudyIntro = (props) => (
    <div className={ClassNames(bem.b, props.className)}>
        <Container>
            <Narrow>
                <div className={bem.e('logo')}>
                    <h1 className="u-accessible-hide">
                        {props.name}
                    </h1>
                    { props.logo ? (
                        <SrcSet
                            className={`logo-${props.name.toLowerCase()}`}
                            url={imageUrl(props.logo)}
                            alt={`${props.name} logo`}
                        />
                    ) : (null) }
                </div>
                { props.logo ? (
                    <div className={bem.e('divider')} style={{background: props.brandColor}} />
                ) : (null) }
                <div className={bem.e('title')}>
                    {props.title}
                </div>
            </Narrow>
        </Container>
    </div>
)

CaseStudyIntro.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    brandColor: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired
}

export default CaseStudyIntro
