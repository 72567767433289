import React from 'react'
import { connect } from 'react-redux'
import Container from 'components/layout/Container'
import Collection from 'components/layout/Collection'
import OutlineItem from 'components/content/OutlineItem'

import BuildIcon from 'images/icons/process/build.svg'
import CreateIcon from 'images/icons/process/create.svg'
import EducationIcon from 'images/icons/process/educate.svg'
import IdeateIcon from 'images/icons/process/ideate.svg'
import ValidateIcon from 'images/icons/process/validate.svg'

const HowOutline = (props) => {
    const _itemsWithIcons = (steps) => {
        return steps.map((item) => {
            let icon;

            switch (item.icon_name) {
                case 'build':
                    icon = BuildIcon;
                    break;
                case 'create':
                    icon = CreateIcon;
                    break;
                case 'educate':
                    icon = EducationIcon;
                    break;
                case 'ideate':
                    icon = IdeateIcon;
                    break;
                case 'validate':
                    icon = ValidateIcon;
                    break;
                default:
                    break;
            }

            item.icon = icon;
            return item;
        });
    }

    return (
        <Container>
            <Collection
                items = {_itemsWithIcons(props.steps)}
                component = {OutlineItem}
                className = 'outline'
            />
        </Container>
    )
}

const mapStateToProps = (state) => {
    return state.how.process
}

export default connect(mapStateToProps, null)(HowOutline)
