import React from 'react'
import { connect } from 'react-redux'
import BEM from 'lib/BEM'
import ClassNames from 'classnames'
import Container from 'components/layout/Container'
import Canvas from 'components/global/Canvas'
import CareersForm from 'components/partials/contactForms/CareersForm'
import Button from 'components/global/Button'

export const ContactCareers = (props) => {
    const bem = new BEM('contact-main');

    return (
        <Canvas styleModifier = "alt">
            <Container>
                <div className={ClassNames(bem.b, props.className)} style={{background: "#F3F3F4"}}>
                    <div className={bem.e('location')}>
                        <p className={bem.e('location-title')}>
                            {props.location.title}
                        </p>
                        <Button className={bem.e('location-button')}
                            {...props.location.cta}
                        />
                    </div>
                    <CareersForm className={bem.e('form')} />
                </div>
            </Container>
        </Canvas>
    )
}

const mapStateToProps = (state) => {
    return state.careers
}

export default connect(mapStateToProps, null)(ContactCareers)
