import appReducer from './reducers'
import thunk from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'

const middlewareTemplate = store => next => action => {
    let result;
    result = next(action);

    return result;
}

export default (initialState = {}) => {
    return applyMiddleware(thunk, middlewareTemplate)(createStore)(appReducer, initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
}
