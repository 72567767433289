import React from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'
import BEM from 'lib/BEM'

export class Checkbox extends React.Component {
    constructor(props) {
        super(props);

        this.bem = new BEM('checkbox');
        this.inputRef = React.createRef();
    }

    _handleChange(event) {
        if (this.props.valueChange) {
            this.props.valueChange(event);
        }
    }

    render() {
        let bem = this.bem,
            props = this.props;

        return (
            <div className={ClassNames(bem.b, {
                'is-checked': this.props.checked,
            })}>
                <input
                    className={ClassNames(bem.e('input'), props.className, {
                        [bem.e('input--radio')]: props.type === 'radio'
                    })}
                    ref={this.inputRef}
                    id={props.id}
                    name={props.name}
                    value={props.value}
                    aria-labelledby={props.labelId}
                    type={props.type}
                    checked={this.props.checked}
                    required={props.required}
                    disabled={props.disabled}
                    onChange={(event) => this._handleChange(event)}
                    onClick={props.onClick}
                />
            </div>
        );
    }
}

Checkbox.defaultProps = {
    type: 'checkbox',
    checked: false
};

Checkbox.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    labelId: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    checked: PropTypes.bool,
    valueChange: PropTypes.func
};

export default Checkbox;
