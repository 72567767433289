import React from 'react'
import { connect } from 'react-redux'
import BEM from 'lib/BEM'
import ClassNames from 'classnames'
import Container from 'components/layout/Container'
import ContactInfo from 'components/global/ContactInfo'
import BusinessForm from 'components/partials/contactForms/BusinessForm'

export const ContactBusiness = (props) => {
    const bem = new BEM('contact-main');

    return (
        <Container>
            <div className={ClassNames(bem.b, props.className)}>
                <div className={bem.e('location')}>
                    <p className={bem.e('location-title')}>
                        {props.location.title}
                    </p>
                    <ContactInfo className={bem.e('contact-info')} />
                </div>
                <BusinessForm className={bem.e('form')} />
            </div>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return state.contact
}

export default connect(mapStateToProps, null)(ContactBusiness)
