import React from 'react';
import PropTypes from 'prop-types';

const Collection = (props) => {
    const blockClassName = props.className;

    let _element = (name) => {
        return blockClassName + '__' + name;
    }

    let _eachItem = (item, index) => {
        let Component = props.component;

        return (
            <li key={index} className={_element('item')}>
                <Component {...item} />
            </li>
        )
    }

    return (
        <ul className={blockClassName}>
            {props.items.map(_eachItem)}
        </ul>
    )
}

Collection.defaultProps = {
    className: 'collection'
}

Collection.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.object
    ).isRequired,
    component: PropTypes.func.isRequired,
    className: PropTypes.string
}

export default Collection;
