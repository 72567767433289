import React from 'react'
import PropTypes from 'prop-types'
import List from 'components/content/List'
import CircledIcon from 'components/global/CircledIcon'
import CircledImage from 'components/global/CircledImage'
import classNames from 'classnames'

const OutlineItem = (props) => {
    const blockClassName = 'outline-item';

    let _element = (name) => {
        return blockClassName + '__' + name;
    }

    return (
        <div className={blockClassName}>
            {props.icon ? (
                <div className={_element('icon')}>
                    <CircledIcon
                        icon = {props.icon}
                    />
                </div>
            ) : (
                <div className={_element('image')}>
                    <CircledImage
                        image = {props.image}
                    />
                </div>
            )}
            <div className={_element('text')}>
                <h2 className={_element('title')}>
                    {props.title}
                </h2>
                <div className={_element('body')}>
                    <div className={classNames(
                        't-large-body',
                        _element('description')
                    )}>
                        {props.description}
                    </div>
                    <div className={_element('points')}>
                        <List
                            items = {props.points}
                            listStyle = "small"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

OutlineItem.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.string,
    points: PropTypes.arrayOf(
        PropTypes.string
    )
}

export default OutlineItem
