import React from 'react'
import BEM from 'lib/BEM'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'

import { imageUrl } from 'lib/utils'
import Container from 'components/layout/Container'

const Banner = (props) => {
    const bem = new BEM('content-block-banner');

    return (
        <div className={ClassNames(bem.b, props.className)}>
            <Container>
                <img
                    src={imageUrl(props.image)}
                    alt=""
                    className={bem.e('image')}
                />
            </Container>
        </div>
    )
}

Banner.propTypes = {
    image: PropTypes.string.isRequired,
}

export default Banner
