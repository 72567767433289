import React from 'react'
import PropTypes from 'prop-types'
import Canvas from 'components/global/Canvas'
import Container from 'components/layout/Container'
import Headline from 'components/global/Headline'

const BlockquoteFrieze = (props) => {
    const blockClassName = 'blockquote-frieze';

    return (
        <div className={blockClassName}>
            <Canvas
                tick = {true}
                backgroundPhoto = {props.backgroundPhoto}
            >
                <Container>
                    <Headline
                        title = {props.title}
                        description = {props.description}
                    />
                </Container>
            </Canvas>
        </div>
    )
}

BlockquoteFrieze.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    backgroundPhoto: PropTypes.string
}

export default BlockquoteFrieze
