import React from 'react'
import { connect } from 'react-redux'
import Container from 'components/layout/Container'
import Canvas from 'components/global/Canvas'
import classNames from 'classnames'
import { imageUrl } from 'lib/utils'
import List from 'components/content/List'

const HowTeam = (props) => {
    const blockClassName = 'how-team';

    let _element = (name) => {
        return blockClassName + '__' + name;
    }

    let Photo = (props) => (
        <div className={_element('photo')}>
            <img
                src = {imageUrl(props.photo)}
                alt = ""
                className = {_element('photo-actual')}
            />
        </div>
    )

    return (
        <Canvas styleModifier="gradient">
            <Container>
                <div className={blockClassName}>
                    <div className={_element('text')}>
                        <h3 className={classNames(
                            't-h2',
                            _element('title')
                        )}>
                            {props.title}
                        </h3>
                        <div className={classNames(
                            't-large-body',
                            _element('body')
                        )}>
                            {props.description}
                        </div>
                        <div className = {_element('points')}>
                            <List items = {props.points} />
                        </div>
                    </div>
                    <div className={_element('photos')}>
                        <div className={_element('photos__left')}>
                            <Photo photo={props.photos[0]} />
                            <Photo photo={props.photos[1]} />
                        </div>
                        <div className={_element('photos__right')}>
                            <Photo photo={props.photos[2]} />
                        </div>
                    </div>
                </div>
            </Container>
        </Canvas>
    )
}

const mapStateToProps = (state) => {
    return state.how.team
}

export default connect(mapStateToProps, null)(HowTeam)
