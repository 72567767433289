import React from 'react'
import PropTypes from 'prop-types'
import { ctaProps } from 'lib/propShapes'
import { imageUrl } from 'lib/utils'

import Button from 'components/global/Button'
import Container from 'components/layout/Container'

const BigQuote = (props) => {
    const blockClassName = 'big-quote';

    let _element = (name) => {
        return blockClassName + '__' + name;
    }

    return (
        <Container>
            <div className={blockClassName}>
                <div className={_element('inner')}>
                    <div className={_element('image')}>
                        <img
                            src = {imageUrl(props.image)}
                            alt = {props.author.name}
                        />
                    </div>

                    <div className={_element('body')}>
                        <div className={_element('quote')}>
                            {props.quote}
                        </div>
                        <dl className={_element('author')}>
                            <dt className={_element('author-name')}>
                                {props.author.name}
                            </dt>
                            <dd className={_element('autor-title')}>
                                {props.author.title}
                            </dd>
                        </dl>

                        {props.cta ? (
                            <div className={_element('cta')}>
                                <Button
                                    {...props.cta}
                                    buttonStyle = "underline"
                                />
                            </div>
                        ) : (null)}
                    </div>
                </div>
            </div>
        </Container>
    )
}

BigQuote.propTypes = {
    quote: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    author: PropTypes.shape({
        name: PropTypes.string,
        position: PropTypes.string
    }).isRequired,
    cta: PropTypes.shape(ctaProps).isRequired
}

export default BigQuote
