import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

const UtilityMenu = (props) => {
    const blockClassName = 'utility-menu';

    let _element = (name) => {
        return blockClassName + '__' + name;
    }

    let _eachItem = (item, index) => (
        <li
            key = {index}
            className = {_element('item')}
        >
            <a
                href = {item.href}
                className = {_element('link')}
            >
                {item.label}
            </a>
        </li>
    )

    return (
        <nav className={classNames(
            blockClassName,
            props.className
        )}>
            <ul className={_element('items')}>
                {props.items.map(_eachItem)}
            </ul>
        </nav>
    )
}

const mapStateToProps = (state) => {
    return {
        items: state.footerMenu
    }
}

export default connect(mapStateToProps, null)(UtilityMenu)
