import React from 'react'
import BEM from 'lib/BEM'
import ClassNames from 'classnames'
import { connect } from 'react-redux'

import Enemies from 'components/layout/Enemies'
import Button from 'components/global/Button'
import Form from 'components/form/Form'
import FormElement from 'components/form/FormElement'
import Input from 'components/form/Input'
import Textarea from 'components/form/Textarea'
import Checkbox from 'components/form/Checkbox'

import { submitToFormspree } from 'reduxStore/actions'

const formId = 'careers-contact'

export class CareersForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            values: {
                _subject: 'Careers Form',
                _replyto: null,
                name: null,
                message: null,
                subscribe: false
            },
            disabled: true
        }

        this.requiredFields = [
            'name',
            '_replyto',
            'message'
        ];

        this.bem = new BEM('careers-form');
    }

    _handleChange(event) {
        this.setState({
            values: {
                ...this.state.values,
                [event.target.name]: event.target.type === 'checkbox' ?  event.target.checked : event.target.value || null
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        let nullRequiredValues = 0;

        Object.entries(this.state.values).forEach(([field, value]) => {
            if (value === null && this.requiredFields.indexOf(field) > -1) {
                nullRequiredValues++;
            }
        });

        if (nullRequiredValues === 0 && !(!this.state.disabled && !prevState.disabled)) {
            this.setState({
                disabled: false
            })
        }

        if (nullRequiredValues > 0 && prevState.disabled === false) {
            this.setState({
                disabled: true
            })
        }
    }

    render() {
        const bem = this.bem,
              props = this.props;

        return (
            <Form
                className={ClassNames(bem.b, props.className)}
                onChange={(event) => this._handleChange(event)}
                onSubmit={(event) => {event.preventDefault(); this.props.submitForm(this.state.values);}}
            >
                <FormElement label="Name" hasValue={this.state.values.name !== null} placeholderLabel>
                    <Input value={this.state.values.name} disabled={props.formSubmitted} name="name" />
                </FormElement>
                <FormElement label="Email" hasValue={this.state.values._replyto !== null} placeholderLabel>
                    <Input value={this.state.values._replyto} disabled={props.formSubmitted} type="email" name="_replyto" />
                </FormElement>
                <FormElement label="LinkedIn" hasValue={this.state.values._replyto !== null} placeholderLabel>
                    <Input value={this.state.values.linkedin} disabled={props.formSubmitted} type="string" name="linkedin" />
                </FormElement>
                <FormElement value={this.state.values.message} label="What do you specialize in? The more detailed the better."  hasValue={this.state.values.message !== null} placeholderLabel>
                    <Textarea disabled={props.formSubmitted} name="message" />
                </FormElement>

                <Enemies>
                    <FormElement labelPosition="after" label="Want to get a bit of inspiration in your inbox every month?">
                        <Checkbox checked={this.state.values.subscribe} disabled={props.formSubmitted} name="subscribe" />
                    </FormElement>
                    {!props.formSubmitted ? (
                        <Button
                            disabled={this.state.disabled || props.formSubmitted}
                            isWorking={props.formWorking}
                            type="submit"
                        >
                            Send
                        </Button>
                    ) : (null)}
                </Enemies>
                {props.formSubmitted ? (
                    <p className={bem.e('success')}>
                        Thank you for reaching out! We'll be in touch shortly.
                    </p>
                ) : (null)}
            </Form>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    submitForm: (data) => {
        dispatch(submitToFormspree(formId, data))
    }
})

const mapStateToProps = (state) => ({
    formWorking: state.workingForm === formId,
    formSubmitted: state.submittedForms.indexOf(formId) > -1
})

export default connect(mapStateToProps, mapDispatchToProps)(CareersForm)
