import React from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'
import BEM from 'lib/BEM'

export default class Textarea extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value,
            type: null
        };

        this.bem = new BEM('textarea');
        this.bemTextInput = new BEM('text-input');
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.focus) {
            this._focusInput();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.focus && this.props.focus) {
            this._focusInput();
        }

        if (this.props.value !== prevProps.value) {
            this.setState({
                value: this.props.value
            });
        }
    }

    _focusInput() {
        this.inputRef.current.focus();
    }

    _handleFocus(event) {
        if (this.props.inputFocus) {
            this.props.inputFocus(this.state.value);
        }
    }

    _handleBlur(event) {
        if (this.props.inputBlur) {
            this.props.inputBlur(this.state.value);
        }
    }

    _handleChange(event) {
        let newValue = event.target.value;

        if (this.props.valueChange) {
            this.props.valueChange(event, newValue);
        }

        this.setState({
            value: newValue
        });
    }

    render() {
        let props = this.props;

        let inputProps = {
            name: props.name,
            id: props.id,
            'aria-labelledby': props.labelId,
            rows: props.rows,
            className: ClassNames(this.bem.e('input'), this.bemTextInput.e('input')),
            value: this.state.value || '',
            placeholder: props.placeholder,
            ref: this.inputRef,
            onChange: (event) => this._handleChange(event),
            onFocus: (event) => this._handleFocus(event),
            onBlur: (event) => this._handleBlur(event),
            disabled: props.disabled,
            required: props.required
        };

        return (
            <div className={ClassNames(this.bem.b, this.bemTextInput, props.wrapperClassString, props.className)}>
                {props.instruction ? (
                    <p className={ this.bem.e('instruction') }>
                        {props.instruction}
                    </p>
                ) : (null)}
                <textarea
                    {...inputProps}
                />
            </div>
        );
    }
}

Textarea.defaultProps = {
    focus: false,
    value: '',
    autocomplete: true,
    wrapperClassString: '',
    rows: 4
};

Textarea.propTypes = {
    focus: PropTypes.bool,
    type: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    rows: PropTypes.number,
    instruction: PropTypes.string,
    labelId: PropTypes.string,
    classString: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    valueChange: PropTypes.func,
    inputFocus: PropTypes.func,
    inputBlur: PropTypes.func
};
