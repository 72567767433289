import A from 'reduxStore/actionTypes'
import { FORMSPREE_CAREERS, FORMSPREE_CONTACT } from '../constants'

export const setAssetHost = (host) => {
    return {
        type: A.CONFIG__ASSET_HOST,
        payload: host
    }
}

export const setChatVisibility = (visible) => {
    return {
        type: A.CHAT__TOGGLE,
        payload: visible
    }
}

export const setHeaderHeight = (height) => {
    return {
        type: A.HEADER__SET_HEIGHT,
        payload: height
    }
}

export const setHeaderSpacerVisibility = (visible) => {
    return {
        type: A.HEADER__SET_SPACER,
        payload: visible
    }
}

export const setYOffset = (offset) => {
    return {
        type: A.SCROLL__Y_OFFSET,
        payload: offset
    }
}

export const toggleChat = () => {
    return (dispatch, getState) => {
        const chatActive = getState().presentation.chat
        dispatch(setChatVisibility(!chatActive))
    }
}

export const setMobileNavVisibility = (visible) => {
    return {
        type: A.MOBILE_NAV__TOGGLE,
        payload: visible
    }
}

export const toggleMobileNav = () => {
    return (dispatch, getState) => {
        const mobileNavOpen = getState().presentation.mobileNav
        dispatch(setMobileNavVisibility(!mobileNavOpen))
    }
}

export const setFormWorking = (formId) => {
    return {
        type: A.FORM__WORKING,
        payload: formId
    }
}

export const addSubmittedForm = (formId) => {
    return {
        type: A.FORM__SUBMITTED,
        payload: {
            formId: formId,
            timestamp: Date.now()
        }
    }
}

export const formURL = (formID) => {
    var URL = '';

    switch(formID) {
        case 'business-contact':
            URL = FORMSPREE_CONTACT;
            break;
        case 'careers-contact':
            URL = FORMSPREE_CAREERS;
            break;
        default:
            URL = FORMSPREE_CONTACT;
            break;
    }

    return URL;
}

export const submitToFormspree = (formId, data) => {
    return (dispatch, getState) => {
        dispatch(setFormWorking(formId))

        fetch(formURL(formId), {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then((responses) => {
            dispatch(setFormWorking(null))
            dispatch(addSubmittedForm(formId))
        })
        .catch(error => console.error(error))
    }
}
