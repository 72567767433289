import React from 'react'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'
import BEM from 'lib/BEM'
import ClassNames from 'classnames'

import Hero from 'components/hero/Hero'
import Callout from 'components/global/Callout'
import Canvas from 'components/global/Canvas'
import Headline from 'components/global/Headline'
import SrcSet from 'components/global/SrcSet'
import Button from 'components/global/Button'

import Space from 'components/layout/Space'
import Container from 'components/layout/Container'
import CaseStudyIntro from '../components/caseStudies/CaseStudyIntro'
import CapabilitiesOutline from '../components/partials/CapabilitiesOutline'

import { imageUrl } from 'lib/utils'
import CapabilitiesContentList from '../components/capabilities/CapabilitiesContentList'

const About = (props) => {
    if (!props.match.params.slug) {
        return null;
    }

    const bem = new BEM('capability');
    const capability = props.capabilities[props.match.params.slug];

    return (
        <DocumentTitle title={`${capability.name} | Invoke`}>
            <div className={ClassNames(bem.b, bem.m('capability'))}>
                <Hero
                    {...capability.hero}
                    className = "capability-hero"
                    smallTitle
                    dark
                    evenColumns
                >
                    <SrcSet
                        url={imageUrl(capability.hero.image)}
                        alt="web3-header"
                        className="capabilities-hero__image"
                    />
                </Hero>

                <CaseStudyIntro
                    name={capability.intro.name}
                    title={capability.intro.subtitle}
                    brandColor={capability.intro.brandColor}
                    logo={capability.intro.images.logo}
                />

                <Container>
                    <Headline
                        capabilityPage={true}
                        title = {capability.quote.title}
                    />
                </Container>

                <CapabilitiesOutline {...capability.list} />
                
                <Canvas >
                    <Container>
                        <div className={bem.e('cta')}>
                            <Button {...capability.list.cta} />
                        </div>
                    </Container>
                <Space />
                </Canvas>

                <CapabilitiesContentList {...capability.caseStudies} />

                <Callout full {...capability.callout} />
            </div>
        </DocumentTitle>
    )
}

const mapStateToProps = (state) => {
    return {
        capabilities: state.capabilities,
        callout: state.results.caseStudyCallout
    }
}

export default connect(mapStateToProps, null)(About)
