import React from 'react'
import PropTypes from 'prop-types'
import { imageUrl } from 'lib/utils'
import classNames from 'classnames'
import slug from 'slug'
import ClassNames from 'classnames'
import BEM from 'lib/BEM'
import SrcSet from 'components/global/SrcSet'

const LogoPool = (props) => {
    const bem = new BEM('logo-pool');

    let _eachLogo = (item, index) => (
        <li
            className = {bem.e('item')}
            key = {index}
        >
            { props.srcset ? (
                <SrcSet
                    url = {imageUrl(item.logo)}
                    alt = {item.name}
                    className = {classNames(
                        bem.e('logo'),
                        bem.e(`logo--${slug(
                            item.name, {
                                lower: true
                            }
                        )}`)
                    )}
                />
            ) : (
                <img
                    src = {imageUrl(item.logo)}
                    alt = {item.name}
                    className = {classNames(
                        bem.e('logo'),
                        bem.e(`logo--${slug(
                            item.name, {
                                lower: true
                            }
                        )}`)
                    )}
                />
            ) }
        </li>
    )

    return (
        <ul className={ClassNames(bem.b, props.className)}>
            {props.logos.map(_eachLogo)}
        </ul>
    )
}

LogoPool.defaultProps = {
    srcset: false
}

LogoPool.propTypes = {
    logos: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            logo: PropTypes.string
        })
    ).isRequired,
    srcset: PropTypes.bool
}

export default LogoPool
