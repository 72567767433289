import React from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'
import BEM from 'lib/BEM'

export default class Input extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value,
            type: null
        };

        this.bem = new BEM('text-input');

        this.inputRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.focus) {
            this._focusInput();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.focus && this.props.focus) {
            this._focusInput();
        }

        if (this.props.value !== prevProps.value) {
            this.setState({
                value: this.props.value
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.type) {
            return {
                type: nextProps.type
            };
        } else return null;
    }

    _focusInput() {
        this.inputRef.current.focus();
    }

    _handleFocus(event) {
        if (this.props.inputFocus) {
            this.props.inputFocus(this.state.value);
        }
    }

    _handleBlur(event) {
        if (this.props.inputBlur) {
            this.props.inputBlur(this.state.value);
        }
    }

    _handleChange(event) {
        let newValue = event.target.value;

        if (this.props.valueChange) {
            this.props.valueChange(event, newValue);
        }

        this.setState({
            value: newValue
        });
    }

    render() {
        let props = this.props,
            autocomplete = props.autocomplete ? 'on' : 'off',
            autocapitalize = props.autocapitalize ? 'on' : 'off',
            autocorrect = props.autocorrect ? 'on' : 'off';

        let inputProps = {
            type: this.state.type,
            name: props.name,
            id: props.id,
            'aria-labelledby': props.labelId,
            className: this.bem.e('input'),
            value: this.state.value || '',
            maxLength: props.maxLength,
            placeholder: props.placeholder,
            autoComplete: autocomplete,
            autoCapitalize: autocapitalize,
            disabled: props.disabled,
            required: props.required,
            autoCorrect: autocorrect,
            ref: this.inputRef,
            onChange: (event) => this._handleChange(event),
            onFocus: (event) => this._handleFocus(event),
            onBlur: (event) => this._handleBlur(event)
        };

        return (
            <div className={ClassNames(this.bem.b, props.wrapperClassString)}>
                <input { ...inputProps } />
            </div>
        );
    }
}

Input.defaultProps = {
    focus: false,
    type: 'text',
    value: '',
    classString: '',
    autocomplete: true,
    wrapperClassString: '',
    autocapitalize: false,
    autocorrect: true,
};

Input.propTypes = {
    focus: PropTypes.bool,
    type: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    labelId: PropTypes.string,
    classString: PropTypes.string,
    value: PropTypes.string,
    maxlength: PropTypes.number,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    autocomplete: PropTypes.bool,
    autocapitalize: PropTypes.bool,
    autocorrect: PropTypes.bool,
    useWrapper: PropTypes.bool,
    wrapperClassString: PropTypes.string,
    valueChange: PropTypes.func,
    inputFocus: PropTypes.func,
    inputBlur: PropTypes.func
};
