import React from 'react'
import { connect } from 'react-redux'
import Container from 'components/layout/Container'
import Canvas from 'components/global/Canvas'
import classNames from 'classnames'
import { imageUrl } from 'lib/utils'

const AboutPartnerships = (props) => {
    const blockClassName = 'about-partnerships';

    let _element = (name) => {
        return blockClassName + '__' + name;
    }

    let Photo = (props) => (
        <div className={_element('photo')}>
            <a href = {props.photo.link} >
                <img
                src = {imageUrl(props.photo.photo)}
                alt = ""
                className = {_element('photo-actual')}
                style={{maxHeight: "42px"}}
                />
            </a>
        </div>
    )

    return (
        <Canvas styleModifier="gradient">
            <Container>
                <div className={blockClassName}>
                    <div className={_element('text')}>
                        <h3 className={classNames(
                            't-h2',
                            _element('title')
                        )}>
                            {props.title}
                        </h3>
                        
                    </div>
                    <div className={_element('text')}>
                        <div className={classNames(
                            't-large-body',
                            _element('body')
                        )}>
                            {props.description}
                        </div>
                        <div className={_element('photos')}>
                            <Photo photo={props.photos[0]} />
                            <Photo photo={props.photos[1]} />
                            <Photo photo={props.photos[2]} />
                        </div>
                    </div>
                </div>
            </Container>
        </Canvas>
    )
}

const mapStateToProps = (state) => {
    return state.about.partnerships
}

export default connect(mapStateToProps, null)(AboutPartnerships)
