import store from 'reduxStore'

export const imageUrl = (path) => {
    let config = store.getState().config

    if (path.match('http')) {
        return path;
    }

    if (config.assetHost) {
        return `${config.assetHost}${path}`
    } else {
        return path
    }
}

export const leftPad = (number, size = 2) => {
    let padded = '0000000000' + number;
    return padded.slice(0 - size);
}

export const preloadImages = (images) => {
    return new Promise((resolve, reject) => {
        let loaded = 0;

        images.forEach((image) => {
            let imageLoader = new Image();

            imageLoader.onload = () => {
                loaded++;
                if (loaded === images.length) {
                    resolve(images);
                }
            }

            imageLoader.onerror = () => {
                reject('Could not load image: ' + image);
            }

            imageLoader.src = image;
        })
    })
}

export const makeId = () => ("_" + Math.random().toString(36).substr(2, 9));
