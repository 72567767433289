import React from 'react'
import BEM from 'lib/BEM'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'

import Container from 'components/layout/Container'

import { COLOR_BRAND } from '../../../constants'

const Quote = (props) => {
    const bem = new BEM('content-block-quote');

    return (
        <Container>
            <div className={ClassNames(bem.b, props.className)}>
                <div className={bem.e('body')}>
                    {props.body}
                    <span className={bem.e('highlight')} style={{background: props.brandColor}} />
                </div>
                <div className={bem.e('author')}>
                    <span className={bem.e('author-name')}>
                        {props.author.name}
                    </span>
                    <span className={bem.e('author-title')}>
                        {props.author.title}
                    </span>
                </div>
            </div>
        </Container>
    )
}

Quote.defaultProps = {
    brandColor: COLOR_BRAND
}

Quote.propTypes = {
    brandColor: PropTypes.string,
    body: PropTypes.string.isRequired,
    author: PropTypes.shape({
        name: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
    }).isRequired
}

export default Quote
