import React from 'react'
import PropTypes from 'prop-types';

export const Columns = (props) => {
    return (
        <div className={`columns columns--${props.count}`}>
            {props.children}
        </div>
    )
}

Columns.defaultPrpos = {
    count: 3
}

Columns.propTypes = {
    count: PropTypes.number
}

export const Column = (props) => {
    return (
        <div className="columns__item">
            {props.children}
        </div>
    )
}
