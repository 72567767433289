import React from 'react'
import PropTypes from 'prop-types'
import { leftPad } from 'lib/utils'

const Index = (props) => {
    const blockClassName = 'index';

    let _element = (name) => {
        return blockClassName + '__' + name;
    }

    let IndexItem = (props, index) => (
        <li key={index} className={_element('item')}>
            <div className={_element('number')}>{leftPad(index + 1)}</div>
            <div className={_element('title')}>{props.title}</div>
            <div className={_element('description')}>{props.description}</div>
        </li>
    )

    return (
        <ul className={blockClassName}>
            {props.items.map(IndexItem)}
        </ul>
    )
}

Index.propTypes = {
    itmes: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.title,
            description: PropTypes.description
        })
    )
}

export default Index
