import React from "react";

export const Label = (props) => (
    <label
        className={props.className}
        id={props.id}
        htmlFor={props.htmlFor}
    >
        {props.children}
    </label>
)

export default Label
