import React from 'react'
import ClassNames from 'classnames'

const Narrow = (props) => {

    return (
        <div className={ClassNames('l-narrow', props.className)}>
            {props.children}
        </div>
    )
}

export default Narrow
