import React from 'react'
import BEM from 'lib/BEM'
import ClassNames from 'classnames'

import MailchimpSubscribe from 'react-mailchimp-subscribe'
import ActiveButton from 'components/global/ActiveButton'

const SignupForm = ({ props, status, message, onValidated }) => {
    let email;

    const bem = new BEM('signup');

    const _submit = (event) => {
        if (email && email.value.indexOf("@") > -1) {
            onValidated({
                EMAIL: email.value
            });
        }

        event.preventDefault();
    }

    return (
        <div className={ClassNames(bem.b, props.className)}>
            <form
                onSubmit={_submit}
                action="/"
                className={bem.e('form')}
            >
                <input
                    className={bem.e('input')}
                    ref={node => (email = node)}
                    type="email"
                    name="email"
                    placeholder={props.placeholder}
                />
                <ActiveButton
                    className={bem.e('submit')}
                    type="submit"
                    label="Count me in"
                    isActive={status === 'sending'}
                />
            </form>
            <p
                className={ClassNames(bem.e('message'), bem.e(`message--${status}`))}
                dangerouslySetInnerHTML={{__html: message}}
            />
        </div>
    )
}

class Signup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submission: 'prestine'
        }
    }


    render() {
        const url = "//invokedigital.us1.list-manage.com/subscribe/post?u=5d6d3047a9429298a01cfd3e7&id=61cfae4b99";

        return (
            <MailchimpSubscribe
                url={url}
                render={({subscribe, status, message}) => (
                    <SignupForm
                        props={this.props}
                        status={status}
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}
            />
        )
    }
}

Signup.defaultProps = {
    placeholder: 'Please enter your email'
}

export default Signup
