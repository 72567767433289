import React from 'react';
import DocumentTitle from 'react-document-title'

const NotFound = (props) => {
    if (window.location.hostname !== 'localhost') {
        // window.location=(props.location.pathname)
    }
    return (
        <DocumentTitle title="Page not found 404 | Invoke">
            <div className="l-container">
                <div className="not-found">
                    <strong>404. Blame Wes.</strong><br />
                    <small>We're sorry, something is broken.</small>
                </div>
            </div>
        </DocumentTitle>
    )
}

export default NotFound
