import React from 'react'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'
import BEM from 'lib/BEM'
import ClassNames from 'classnames'
import { Helmet } from 'react-helmet';

import Callout from 'components/global/Callout'
import CaseStudyHero from 'components/caseStudies/CaseStudyHero'
import CaseStudyIntro from 'components/caseStudies/CaseStudyIntro'

import Banner from 'components/caseStudies/contentBlocks/Banner'
import Blockquote from 'components/caseStudies/contentBlocks/Blockquote'
import Content from 'components/caseStudies/contentBlocks/Content'
import Expose from 'components/caseStudies/contentBlocks/Expose'
import Quote from 'components/caseStudies/contentBlocks/Quote'
import Slider from 'components/caseStudies/contentBlocks/Slider'

const About = (props) => {
    if (!props.match.params.slug) {
        return null;
    }

    const bem = new BEM('case-study');
    const caseStudy = props.caseStudies[props.match.params.slug];

    const contentBlocks = {
        'banner': Banner,
        'blockquote': Blockquote,
        'content': Content,
        'expose': Expose,
        'quote': Quote,
        'slider': Slider
    }

    const ContentBlock = (block, index) => {
        const Component = contentBlocks[block.type];
        return (
            <Component
                key={block.type + '-' + index}
                className={ClassNames('content-block', `content-block--${block.type}`)}
                {...block}
                brandColor={caseStudy.brandColor}
            />
        )
    }

    return (
        <DocumentTitle title={`${caseStudy.name} | Invoke`}>
            <div className={ClassNames(bem.b, bem.m('case-study'))}>
                <Helmet>
                    <meta name="title" content={caseStudy.metaTitle} />
                    <meta name="description" content={caseStudy.metaDescription} />
                </Helmet>
                <CaseStudyHero
                    headline={caseStudy.shortDescription}
                    photo={caseStudy.images.hero}
                    url={caseStudy.url}
                    scope={caseStudy.scopeOfWork}
                    brandColor={caseStudy.brandColor}
                />
                <CaseStudyIntro
                    name={caseStudy.name}
                    title={caseStudy.subtitle}
                    brandColor={caseStudy.brandColor}
                    logo={caseStudy.images.logo}
                />

                {caseStudy.contentBlocks.map((block, index) => (
                    ContentBlock(block, index)
                ))}

                <Callout {...props.callout} />
            </div>
        </DocumentTitle>
    )
}

const mapStateToProps = (state) => {
    return {
        caseStudies: state.caseStudies,
        callout: state.results.caseStudyCallout
    }
}

export default connect(mapStateToProps, null)(About)
