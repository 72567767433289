import React from 'react'
import { connect } from 'react-redux'
import Container from 'components/layout/Container'
import PhotoStrew from 'components/photo/PhotoStrew'

const AboutOpportunity = (props) => {
    const blockClassName = 'about-opportunity';

    let _element = (name) => {
        return blockClassName + '__' + name;
    }

    return (
        <Container>
            <div className={blockClassName}>
                <div className = {_element('photos')}>
                    <PhotoStrew photos={props.photos} />
                </div>
                <div
                    className = {_element('body')}
                    dangerouslySetInnerHTML = {{__html: props.body}}
                />
            </div>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return state.about.opportunity
}

export default connect(mapStateToProps, null)(AboutOpportunity)
