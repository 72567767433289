import React from 'react'
import { connect } from 'react-redux'
import BEM from 'lib/BEM'
import ClassNames from 'classnames'

import Container from 'components/layout/Container'
import Narrow from 'components/layout/Narrow'
import Headline from 'components/global/Headline'
import CaseStudyTile from 'components/caseStudies/CaseStudyTile'

export const ContactNewsletter = (props) => {
    const bem = new BEM('contact-work');

    return (
        <div className={ClassNames(bem.b, props.className)}>
            <Container>
                <Narrow>
                    <Headline title={props.title} />
                </Narrow>
                <div className={bem.e('case-studies')}>
                    {props.work.map((client, index) => (
                        <CaseStudyTile
                            className={bem.e('case-study')}
                            key={index}
                            slug={client}
                            displayStyle='tileWithImage'
                            {...props.allCaseStudies[client]}
                        />
                    ))}
                </div>
            </Container>
        </div>
    )
}

const mapStateToProps = (state) => ({
    ...state.contact.caseStudies,
    allCaseStudies: state.caseStudies
})

export default connect(mapStateToProps, null)(ContactNewsletter)
