const actionTypes = {
    CHAT__TOGGLE: 'CHAT__TOGGLE',
    MOBILE_NAV__TOGGLE: 'MOBILE_NAV__TOGGLE',
    CONFIG__ASSET_HOST: 'CONFIG__ASSET_HOST',
    HEADER__SET_HEIGHT: 'HEADER__SET_HEIGHT',
    HEADER__SET_SPACER: 'HEADER__SET_SPACER',
    SCROLL__Y_OFFSET: 'SCROLL__Y_OFFSET',
    FORM__WORKING: 'FORM__WORKING',
    FORM__SUBMITTED: 'FORM__SUBMITTED'
}

export default actionTypes
