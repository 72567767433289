import storeFactory from './factory'
import menu from 'content/menu.json'
import footerMenu from 'content/footerMenu.json'
import home from 'content/home.json'
import how from 'content/how.json'
import about from 'content/about.json'
import results from 'content/results.json'
import careers from 'content/careers.json'
import contact from 'content/contact.json'
import globalContent from 'content/globalContent.json'

import brightkit from 'content/case-studies/brightkit.json'
import foodee from 'content/case-studies/foodee.json'
import instantFinancial from 'content/case-studies/instant-financial.json'
import modo from 'content/case-studies/modo.json'
import mojio from 'content/case-studies/mojio.json'
import ritualMusic from 'content/case-studies/ritual-music.json'
import saveOnFoods from 'content/case-studies/save-on-foods.json'
import hootsuite from 'content/case-studies/hootsuite.json'
import nbc from 'content/case-studies/nbc-the-voice.json'
import earls from 'content/case-studies/earls-social-menu.json'
import luvo from 'content/case-studies/luvo.json'
import vancouverClub from 'content/case-studies/vancouver-club.json'
import bcaa from 'content/case-studies/bcaa.json'
import dwave from 'content/case-studies/d-wave.json'
import think from 'content/case-studies/think.json'
import davidSuzukiFoundation from 'content/case-studies/david-suzuki-foundation.json'
import degen from 'content/case-studies/degen.json'
import genzeroes from 'content/case-studies/genzeroes.json'
import luckiesNft from 'content/case-studies/luckies-nft.json'

import ai from 'content/capabilities/ai.json'
import web3 from 'content/capabilities/web3.json'
import b2b from 'content/capabilities/b2b.json'
import marketplaces from 'content/capabilities/marketplaces.json'
import ideaAccelerator from 'content/capabilities/ideaAccelerator.json'

let initialState = {
    globalContent: globalContent,
    menu: menu,
    footerMenu: footerMenu,
    home: home,
    about: about,
    results: results,
    capabilities: {
        'ai': ai,
        'web3': web3,
        'b2b': b2b,
        'marketplaces': marketplaces,
        'idea-accelerator': ideaAccelerator,
    },
    how: how,
    careers: careers,
    contact: contact,
    caseStudies: {
        'brightkit': brightkit,
        'foodee': foodee,
        'instant-financial': instantFinancial,
        'modo': modo,
        'mojio': mojio,
        'ritual-music': ritualMusic,
        'save-on-foods': saveOnFoods,
        'hootsuite': hootsuite,
        'nbc-the-voice': nbc,
        'earls-social-menu': earls,
        'luvo': luvo,
        'vancouver-club': vancouverClub,
        'bcaa': bcaa,
        'd-wave': dwave,
        'think': think,
        'david-suzuki-foundation': davidSuzukiFoundation,
        'degen': degen,
        'genzeroes': genzeroes,
        'luckies-nft': luckiesNft,
    }
},
    store = storeFactory(initialState)

export default store;
