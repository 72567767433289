import React from 'react'
import PropTypes from 'prop-types'
import Container from 'components/layout/Container'
import { imageUrl } from 'lib/utils'

const StaggeredGallery = (props) => {
    const blockClassName = 'staggered-gallery';

    let _element = (name) => {
        return blockClassName + '__' + name;
    }

    let Image = (props) => {
        return (
            <img
                className = {_element('photo')}
                src = {imageUrl(props.src)}
                alt = ""
            />
        )
    }

    let _eachSubgridPhoto = (photo, index) => {
        return (
            <Image
                key = {index}
                src = {photo}
            />
        )
    }

    return (
        <Container>
            <div className={blockClassName}>
                <div className={_element('left-column')}>
                    <Image
                        src = {props.photos[0]}
                    />
                </div>
                <div className={_element('right-column')}>
                    <div className={_element('sub-grid')}>
                        {props.photos.slice(1).map(_eachSubgridPhoto)}
                    </div>
                </div>
            </div>
        </Container>
    )
}

StaggeredGallery.propTypes = {
    photos: PropTypes.arrayOf(
        PropTypes.string
    )
}

export default StaggeredGallery
