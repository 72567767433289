import React from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'

const SrcSet = (props) => {
  const splitUrl = props.url.split('.');
  const extension = splitUrl[splitUrl.length - 1];
  const withoutExtension = splitUrl.slice(0, -1).join('.');

  return (
    <img
      className={ ClassNames('srcset', props.className) }
      alt={ props.string }
      src={ props.url }
      srcSet={ `
        ${withoutExtension}@2x.${extension} 2x,
        ${withoutExtension}@3x.${extension} 3x
        ` }
    />
  )
}

SrcSet.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string
}

export default SrcSet
