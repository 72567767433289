import React from 'react'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'
import BEM from 'lib/BEM'
import ClassNames from 'classnames'

import Hero from 'components/hero/Hero'
import Callout from 'components/global/Callout'
import Space from 'components/layout/Space'
import Container from 'components/layout/Container'
import Canvas from 'components/global/Canvas'
import Headline from 'components/global/Headline'
import ResultsScreenshots from 'components/partials/ResultsScreenshots'
import CaseStudyList from 'components/caseStudies/CaseStudyList'
import LogoPool from 'components/content/LogoPool'

const Results = (props) => {
    const bem = new BEM('page');

    return (
        <DocumentTitle title="Our Results | Invoke">
            <div className={ClassNames(bem.b, bem.m('results'))}>
                <Hero
                    {...props.hero}
                    className = "results-hero"
                    smallTitle
                    dark
                    evenColumns
                >
                    <ResultsScreenshots/>
                </Hero>

                <Container>
                    <Space inside>
                        <CaseStudyList list={props.caseStudies} />
                    </Space>
                </Container>

                <Canvas className='results-logo-pool' styleModifier='gradient'>
                    <Container>
                        <Headline title={props.logoPool.title} />
                        <LogoPool
                            logos={props.logoPool.logos}
                            srcset
                        />
                    </Container>
                </Canvas>

                <Space />
                <Callout full {...props.callout} />
            </div>
        </DocumentTitle>
    )
}

const mapStateToProps = (state) => {
    return state.results
}

export default connect(mapStateToProps, null)(Results)
