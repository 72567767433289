import React from 'react'
import ClassNames from 'classnames'
import BEM from 'lib/BEM'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import CaseStudyTile from 'components/caseStudies/CaseStudyTile'

export class CaseStudyList extends React.Component {
    constructor(props) {
        super(props);

        this.displayStylePattern = [
            'rowWithImage',
            'tileWithImage',
            'tile',
            'tile',
        ]

        this.currentDisplayStyleIndex = 0;

    }

    _getDisplayPattern() {
        let match = this.displayStylePattern[this.currentDisplayStyleIndex];

        if ((this.currentDisplayStyleIndex + 1) === this.displayStylePattern.length) {
            this.currentDisplayStyleIndex = 0;
        } else {
            this.currentDisplayStyleIndex++;
        }

        return match;
    }

    _groupItems(items) {
        let groupsOfItems = [],
            currentGroup = [],
            totalGrouped = 0;

        this.props.list.forEach((item, index) => {
            currentGroup.push(item);
            totalGrouped++;

            if (index % 4 === 0) {
                groupsOfItems.push(currentGroup);
                currentGroup = [];
            } else if (currentGroup.length === 3 || totalGrouped === this.props.list.length) {
                let withSubGroups = [currentGroup.slice(0, 1)];
                withSubGroups.push(currentGroup.slice(1, 3));
                groupsOfItems.push(withSubGroups);
                currentGroup = [];
            }
        });

        return groupsOfItems;
    }

    render() {
        const bem = new BEM('case-study-list');
        const allCaseStudies = this.props.caseStudies;
        const groupedCaseStudies = this._groupItems(this.props.caseStudies);

        return (
            <div className={ClassNames(bem.b, this.props.className)}>
                { groupedCaseStudies.map((group, index) => (
                    <div className={ClassNames(bem.e('group'), {
                        [bem.e(`group--with-subgroups`)]: group.length > 1
                    })} key={index}>
                        { group.map((caseStudy, mapIndex) => (
                            typeof(caseStudy) === 'string' ? (
                                <CaseStudyTile
                                    className={ClassNames(bem.e('item'), bem.e(`item-${mapIndex + 1}`))}
                                    key={caseStudy}
                                    slug={caseStudy}
                                    displayStyle={this._getDisplayPattern()}
                                    { ...allCaseStudies[caseStudy] }
                                />
                            ) : (
                                <div className={ClassNames(bem.e('sub-group'), bem.e(`sub-group--of-${caseStudy.length}`))} key={mapIndex}>
                                    { caseStudy.map((subItem, subItemIndex) => (
                                        <CaseStudyTile
                                            className={ClassNames(bem.e('item'), bem.e(`item-${mapIndex + 1}`))}
                                            key={subItem}
                                            slug={subItem}
                                            displayStyle={this._getDisplayPattern()}
                                            { ...allCaseStudies[subItem] }
                                        />
                                    )) }
                                </div>
                            )
                        )) }
                    </div>
                ))}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        caseStudies: state.caseStudies
    }
}

CaseStudyList.propTypes = {
    list: PropTypes.arrayOf(
        PropTypes.string
    )
}

export default connect(mapStateToProps, null)(CaseStudyList)
