import React from 'react'
import ClassNames from 'classnames'
import BEM from 'lib/BEM'
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom'
import { imageUrl } from 'lib/utils'
import { PATH_CASE_STUDY } from '../../constants'

import SrcSet from 'components/global/SrcSet'

const CaseStudyTile = (props) => {
    const bem = new BEM('case-study-tile');

    return (
        <Link
            to={PATH_CASE_STUDY.replace(':slug', props.slug)}
            className={ClassNames(bem.b, props.className, bem.m(props.displayStyle), bem.m(props.slug))}
        >
            {props.displayStyle !== 'tile' ? (
                <div className={bem.e('preview')} style={{background: props.brandColor}}>
                    <div className={bem.e('preview-image')}>
                        <SrcSet url={imageUrl(props.preview.image)} alt="" className={ClassNames(bem.e('preview-image-actual'), {
                            [bem.e(`preview-image--${props.preview.alignment}`)]: props.preview.alignment
                        })} />
                    </div>
                    {props.displayStyle === 'rowWithImage' && props.preview.background ? (
                        <div className={bem.e('preview-background-image')} style={{
                            backgroundImage: `url(${imageUrl(props.preview.background)})`,
                            backgroundSize: 'cover'
                        }} />
                    ) : (null)}
                </div>
            ) : (null)}

            <div className={bem.e('body')}>
                <h3 className={bem.e('title')}>
                    <span className={bem.e('name')} style={{color: props.brandColor}}>
                        {props.name}
                    </span>
                    {props.category ? (
                        <span className={bem.e('category')}>
                            {props.category}
                        </span>
                    ) : (null)}
                </h3>
                <p className={bem.e('description')} style={['tileWithImage', 'rowWithImage'].indexOf(props.displayStyle) > -1 ? {color: props.brandColor} : null}>
                    {props.shortDescription}
                </p>
            </div>
            <div className={bem.e('branded-screen')} style={{background: props.brandColor}} />
        </Link>
    )
}

CaseStudyTile.defaultProps = {
    displayStyle: 'tile'
}

CaseStudyTile.propTypes = {
    slug: PropTypes.string.isRequired,
    displayStyle: PropTypes.oneOf([
        'tile',
        'tileWithImage',
        'rowWithImage'
    ])
}

export default CaseStudyTile
