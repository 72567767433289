import React from 'react'
import PropTypes from 'prop-types'
import BEM from 'lib/BEM'
import ClassNames from 'classnames'
import { Link } from 'react-router-dom'

const Button = (props) => {
    const bem = new BEM('button');

    let Container = (props) => <button { ...props }>{ props.children }</button>;

    if (props.href) {
        Container = (props) => <a { ...props }>{ props.children }</a>;
    }

    if (props.linkTo) {
        Container = Link;
    }

    return (
        <Container
            style={{'--underline-color': props.brandColor}}
            onClick={props.onClick}
            onFocus={props.onFocus}
            href={props.href}
            to={props.linkTo}
            type={props.type}
            target={props.target}
            disabled={props.disabled}
            className={ClassNames(bem.b, props.className, {
                [bem.m('disabled')]: props.disabled,
                [bem.m(props.buttonStyle)]: props.buttonStyle,
                'is-working': props.isWorking && !props.linkTo && !props.href
            })}
        >
            <span className={bem.e('spinner')}>
                <span className='spinner' />
            </span>
            <span className={ bem.e('label') }>
                { props.children ? props.children : null }
                { props.label ? props.label : null }
            </span>
        </Container>
    );
};

Button.defaultProps = {
    isWorking: false
}

Button.propTypes = {
    href: PropTypes.string,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    label: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    isWorking: PropTypes.bool
};

export default Button
