import React from 'react'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'
import BEM from 'lib/BEM'
import ClassNames from 'classnames'

import Hero from 'components/hero/Hero'
import AboutPartnerships from 'components/partials/AboutPartnerships'
import BlockquoteFrieze from 'components/content/BlockquoteFrieze'
import Callout from 'components/global/Callout'
import AboutOpportunity from 'components/partials/AboutOpportunity'
import Slider from 'components/photo/Slider'
import StaggeredGallery from 'components/photo/StaggeredGallery'
import Space from 'components/layout/Space'

const About = (props) => {
    const bem = new BEM('page');

    return (
        <DocumentTitle title="About | Invoke">
            <div className={ClassNames(bem.b, bem.m('about'))}>
                <Hero
                    {...props.hero}
                    className = "hero-with-gallery"
                    layout = "row"
                    dark
                >
                    <StaggeredGallery photos={props.hero.photos} />
                </Hero>
                <AboutPartnerships {...props.partnerships}/>
                <BlockquoteFrieze {...props.opportunity} />
                <AboutOpportunity />
                <Slider photos={props.slides} />
                <Space amount="more" />
                <Callout {...props.callout} />
            </div>
        </DocumentTitle>
    )
}

const mapStateToProps = (state) => {
    return state.about
}

export default connect(mapStateToProps, null)(About)
