import React from 'react'
import PropTypes from 'prop-types'
import Container from 'components/layout/Container'
import Canvas from 'components/global/Canvas'
import ClassNames from 'classnames'
import BEM from 'lib/BEM'

const Hero = (props) => {
    const bem = new BEM('hero');

    let canvasModifier = props.canvas;

    if (props.dark) {
        canvasModifier = 'gradient--dark';
    }

    return (
        <div className={ClassNames(bem.b, bem.m(props.layout), props.className, {
            [bem.m('reverse')]: props.reverse || props.dark,
            [bem.m('dark')]: props.dark,
            [bem.m('even-columns')]: props.evenColumns
        })}>
            <Canvas styleModifier={canvasModifier}>
                <Container className={bem.e('inner')}>
                    <div className={bem.e('text')}>
                        <h2 className={ClassNames(bem.e('title'), {
                            [bem.e('title--long')]: props.title.length > 25 || props.smallTitle
                        })}>
                            {props.title}
                        </h2>
                        <div className={ClassNames(bem.e('description'))}>
                            {props.description}
                        </div>
                    </div>
                    {props.children && props.layout === 'column' ? (
                        <Container className={bem.e('gallery')}>
                                {props.children}
                        </Container>
                    ) : (null)}
                </Container>
            </Canvas>
            {props.children && props.layout === 'row' ? (
                <Container className={bem.e('gallery')}>
                        {props.children}
                </Container>
            ) : (null)}
        </div>
    )
}

Hero.defaultProps = {
    layout: "column"
}

Hero.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    photos: PropTypes.arrayOf(PropTypes.string),
    canvas: PropTypes.string,
    layout: PropTypes.string,
    reverse: PropTypes.bool,
    dark: PropTypes.bool,
    smallTitle: PropTypes.bool,
    evenColumns: PropTypes.bool
}

export default Hero
