import React from 'react'
import { connect } from 'react-redux'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import LogoPool from 'components/content/LogoPool'
import Space from 'components/layout/Space'

const HowClientTabs = (props) => {
    let _tabs = (item, index) => (
        <Tab key={index}>
            {item.title}
        </Tab>
    )

    let _panels = (item, index) => (
        <TabPanel key={index}>
            {item.description}
            <Space amount="little" />
            <LogoPool logos={item.logos} />
        </TabPanel>
    )

    return (
        <Tabs>
            <TabList>
                {props.groups.map(_tabs)}
            </TabList>
            {props.groups.map(_panels)}
        </Tabs>
    )
}

const mapStateToProps = (state) => {
    return {
        groups: state.how.clients.groups
    }
}

export default connect(mapStateToProps, null)(HowClientTabs)
