import React from 'react'
import store from 'reduxStore'
import { toggleMobileNav } from 'reduxStore/actions'

const Hamburger = (props) => {
    const blockClassName = 'hamburger';

    let _element = (name) => {
        return blockClassName + '__' + name;
    }

    let _handleClick = () => {
        store.dispatch(toggleMobileNav())
    }

    return (
        <button
            className = {`${blockClassName} ${props.className} button--symantic`}
            onClick = {_handleClick}
        >
            <span className={_element('center')} />
            <span className="u-accessible-hide">
                Main navigation
            </span>
        </button>
    )
}

export default Hamburger
